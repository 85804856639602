import React, { useMemo } from 'react';
import { generatePath } from 'react-router';

import { useEditor } from '@toasttab/sites-components';
import parsePhoneNumber, { CountryCode } from 'libphonenumber-js';

import { DiningOptionBehavior } from 'src/apollo/onlineOrdering';
import { FooterConfig, RestaurantDataFragment } from 'src/apollo/sites';
import { FullSchedule, getCurrentScheduleInterval, mergeSchedules } from 'src/lib/js/schedule';
import Link from 'src/shared/components/common/link';

import {
  Restaurant,
  RestaurantLocation,
  useRestaurant
} from 'shared/components/common/restaurant_context/RestaurantContext';
import { useRestaurantRoutes } from 'shared/components/common/restaurant_routes/RestaurantRoutesContext';
import { formatRestaurantAddress, generateRestaurantAddressQuery } from 'shared/js/locationUtils';

import SocialLink from 'public/components/default_template/footer/SocialLink';
import AllHoursModal from 'public/components/default_template/footer/location/AllHoursModal';
import OpenStatus from 'public/components/default_template/footer/location/OpenStatus';
import { ScheduleType } from 'public/components/online_ordering/types';

type Props = {
  restaurant: Restaurant;
  location: RestaurantLocation;
  locationSchedule?: ScheduleType;
  onLocationClick: (locationId: string) => void;
  config?: FooterConfig | null;
  editPath: string;
};

const scheduleDiningOptionBehavior: DiningOptionBehavior = DiningOptionBehavior.TakeOut;

const LocationDescription = ({ restaurant, location, editPath, locationSchedule, onLocationClick, config }: Props) => {
  const { useEditableRef } = useEditor();
  const { orderPathPattern } = useRestaurantRoutes();
  const { ooRestaurant } = useRestaurant();

  const i18nCountryCode = (ooRestaurant?.i18n?.country || 'US') as CountryCode;
  const parsedPhoneNumber = location.phoneNumber
    ? parsePhoneNumber(location.phoneNumber, i18nCountryCode)
    : null;

  const { editableRef } = useEditableRef<HTMLDivElement>({
    name: 'business hours',
    displayName: 'Business Hours',
    path: editPath,
    actions: [],
    schema: { fields: [] }
  });

  const timezoneId = useMemo(() => locationSchedule?.timeZoneId ?? 'America/New_York', [locationSchedule?.timeZoneId]);

  const mergedSchedules: FullSchedule | undefined = useMemo(
    () => mergeSchedules(location, locationSchedule, !!restaurant.config.isOnlineOrderingOnly, scheduleDiningOptionBehavior),
    [location, locationSchedule, restaurant.config.isOnlineOrderingOnly]
  );

  const isLocationOpen = useMemo(() => {
    if(mergedSchedules) {
      return !!getCurrentScheduleInterval(mergedSchedules, timezoneId);
    }
    return false;
  }, [mergedSchedules, timezoneId]);

  const formattedAddress = formatRestaurantAddress(location);
  const addressQuery = generateRestaurantAddressQuery(restaurant.name, location);
  const hasMenuPage = !restaurant.config.menuConfig?.optedOut;

  return (
    <div className="locationDescription" data-testid="footer-location">
      {(restaurant.locations?.length || 0) > 1 ?
        <h3 className="locationName">
          {!restaurant.config.isOnlineOrderingOnly && location.shortUrl ?
            <Link href={`/menu/${location.shortUrl}`} onClick={() => onLocationClick(location.externalId)} className={ !hasMenuPage ? 'disabled' : ''}>
              {location.name}
            </Link>
            : location.name}
        </h3> :
        null}
      <div className="locationPhone" data-testid="location-phone">{parsedPhoneNumber?.formatNational()}</div>
      <div className="locationAddress" >
        <a href={`https://google.com/maps/place?${addressQuery}`} rel="noopener noreferrer" target="_blank">
          {formattedAddress}
        </a>
      </div>
      {!config?.hideHours && mergedSchedules &&
        <div className="locationSchedule" ref={editableRef}>
          <AllHoursModal restaurantName={location.name || restaurant.name} isLocationOpen={isLocationOpen} schedule={mergedSchedules} timezoneId={timezoneId}>
            {open => {
              // Modal Target
              return (
                <div>
                  <div className="status" data-testid="open-status">
                    <OpenStatus isOpen={isLocationOpen} schedule={mergedSchedules} timezoneId={timezoneId} />
                  </div>
                  <button onClick={open} className="hoursToggle"><strong>All hours</strong></button>
                </div>);
            }}
          </AllHoursModal>
        </div>}
      <div className="foodLinks">
        {!config?.hideMenuLink && !restaurant.config.isOnlineOrderingOnly && location.shortUrl ?
          <div className="locationMenu">
            <Link href={`/menu/${location.shortUrl}`} onClick={() => onLocationClick(location.externalId)}>
              View menu
            </Link>
          </div>
          : null}
        {!config?.hideOOLink && restaurant.config.isOnlineOrderingEnabled && location.shortUrl ?
          <div className="locationMenu">
            <Link href={generatePath(orderPathPattern, { slug: location.shortUrl })} onClick={() => onLocationClick(location.externalId)}>
              Order online
            </Link>
          </div>
          : null}
      </div>
      {!config?.hideSocialLinks &&
        <div className="socialIcons">
          {location.meta?.urls?.map((url: string) => <SocialLink url={url} key={url} color={getFooterColorFromRestaurant(restaurant)} />)}
        </div>}
    </div>
  );
};


const getFooterColorFromRestaurant = (restaurant: RestaurantDataFragment): string => {
  if(restaurant.theme?.enabled) {
    return restaurant?.theme?.colorOverrides?.footerColorOverrides?.text || restaurant?.theme?.colorScheme?.text?.default || '#ffffff';
  }
  return restaurant?.content?.footerConfig?.colors?.text || '#ffffff';
};

export default LocationDescription;

import React, { useMemo } from 'react';

import { FieldType, useEditor } from '@toasttab/sites-components';
import { produce } from 'immer';

import { BackgroundImage, Block as BlockType, PaddingEnum } from 'src/apollo/sites';
import { getBackgroundColorOrImageModule, getSectionPaddingModule } from 'src/shared/components/common/editor_context/editableUtils';
import { ScreenWidth, useIsMobile } from 'src/shared/js/utils/WindowContext';

import { DESKTOP, getNumRowsFromBlock, GRID_GAP, hasMobilePosition, isInRightHalf, MOBILE } from 'public/components/default_template/dynamic_section/DynamicSectionUtils';

import { Block, BlockWrapper } from './Block';

const MAX_CONTENT_WIDTH = '1400px';

// If margin is set to a non-zero value, then in the mobile view the margin will always be 4vw.
const MOBILE_HORIZONTAL_MARGIN = 4;

type Props = {
  blocks: BlockType[];
  numRows: number;
  mobileNumRows?: number | null;
  numCols?: number;
  mobileNumCols?: number;
  editPath: string;
  padding: PaddingEnum;
  backgroundColor?: string | null;
  backgroundImage?: BackgroundImage | null;
  isPopup?: boolean;
  cellWidthCSS?: string; // css to define the width of a cell within DynamicSection's grid
  cellHeightCSS?: string; // css to define the height of a cell within DynamicSection's grid
  horizontalMargin?: number | null;
}

export const DynamicSection = ({
  blocks, editPath, numRows, mobileNumRows, padding, backgroundColor, backgroundImage,
  numCols, mobileNumCols, isPopup, cellWidthCSS, cellHeightCSS, horizontalMargin
}: Props) => {
  const { isEditor, dynamicModuleWrapper: DynamicModuleWrapper, useEditableRef } = useEditor();
  const isMobile = useIsMobile(ScreenWidth.SMALL);

  const sortedBlocks: BlockType[] = useMemo(() => {
    // We only need this sorted value if we are in mobile view,
    // so exit early if we are in desktop view.
    if(!isMobile) { return blocks; }

    return produce(blocks, draft => {
      return draft.sort((a: BlockType, b: BlockType) => {
        //  A negative value indicates that a should come before b.
        //  A positive value indicates that a should come after b.
        //  Zero or NaN indicates that a and b are considered equal.

        // Objects with a set mobile location are sorted before objects without one.
        if(hasMobilePosition(a) && !hasMobilePosition(b)) return -1;
        if(!hasMobilePosition(a) && hasMobilePosition(b)) return 1;
        // Don't change the sort order of objects that have a set mobile location.
        if(hasMobilePosition(a) && hasMobilePosition(b)) return 0;

        // Objects in the left half are sorted before objects in the right half.
        if(isInRightHalf(a) && !isInRightHalf(b)) return 1;
        if(!isInRightHalf(a) && isInRightHalf(b)) return -1;
        // Objects are sorted by their starting row.
        // If a.y is smaller than b.y then a should go first. Then we should return negative.
        // a.y - b.y if a is smaller will return negative.
        if(a.startY !== b.startY) return a.startY - b.startY;
        // Objects are sorted by their starting column.
        if(a.startX !== b.startX) return a.startX - b.startX;
        return 0;
      });
    });
  }, [blocks, isMobile]);


  const computedMobileNumRows: number = useMemo(() => {
    // The largest explicitly set mobile endY.
    const largestMobileEndY = sortedBlocks
      .filter(hasMobilePosition)
      .reduce((val, block) => Math.max(block?.mobileEndY || 0, val), 0);
    // The number of mobile rows taken up by blocks that don't have an explict mobile location set.
    const numMobileRows = sortedBlocks
      .filter(block => !hasMobilePosition(block))
      .reduce((val, block) => val + getNumRowsFromBlock(block, true), 0);
    // The blocks that don't have an explicit mobile location will get added
    // onto the bottom of the explicitly set mobile view.
    return largestMobileEndY + numMobileRows;
  }, [sortedBlocks]);

  const gridStyle = useMemo(
    () => {
      const calcdRows = isMobile ? computedMobileNumRows : numRows;
      const calcdCols = isMobile ? mobileNumCols ?? MOBILE.NUM_COLS : numCols ?? DESKTOP.NUM_COLS;
      return gridCSS(calcdRows, calcdCols, isMobile, cellWidthCSS, cellHeightCSS);
    },
    [isMobile, computedMobileNumRows, numRows, numCols, mobileNumCols, cellWidthCSS, cellHeightCSS]
  );

  const { editableRef } = useEditableRef({
    name: 'dynamic section',
    actions: ['delete', 'duplicate'],
    displayName: 'Section',
    path: editPath,
    schema: {
      fields: [
        getSectionPaddingModule(editPath, padding),
        getBackgroundColorOrImageModule(editPath, backgroundColor, backgroundImage),
        {
          displayName: 'Rows (default view)',
          type: FieldType.Number,
          path: `${editPath}.numRows`,
          value: numRows,
          validation: { min: 4, max: 60, step: 1 }
        },
        {
          displayName: 'Rows (mobile view)',
          type: FieldType.Number,
          path: `${editPath}.mobileNumRows`,
          value: computedMobileNumRows,
          validation: { min: computedMobileNumRows, max: Math.max(130, computedMobileNumRows), step: 1 }
        },
        {
          displayName: 'Horizontal Margin',
          type: FieldType.HorizontalMargin,
          path: `${editPath}.horizontalMargin`,
          value: horizontalMargin
        }
      ]
    }
  });


  const calculatedMargin = useMemo(() => {
    if(!horizontalMargin) return 0;
    // Mobile view always has a 4vw margin if margin is set.
    return isMobile ? MOBILE_HORIZONTAL_MARGIN : horizontalMargin;
  }, [isMobile, horizontalMargin]);
  // Don't allow the content to be wider than MAX_CONTENT_WIDTH.
  const widthStyle = `min(100% - ${2 * calculatedMargin}vw, ${MAX_CONTENT_WIDTH})`;

  // a dynamic section within a popup is editable within the popup content form
  if(isEditor && !isPopup && DynamicModuleWrapper) {
    return <DynamicModuleWrapper
      ref={editableRef}
      blockComponent={Block}
      blocks={sortedBlocks}
      numRows={numRows}
      mobileNumRows={Math.max(mobileNumRows ?? 0, computedMobileNumRows)}
      editPath={editPath}
      horizontalMargin={horizontalMargin} />;
  }

  let lastMobileRow = 0;

  return (
    <div style={{ width: widthStyle, display: 'flex', zIndex: 1, position: 'relative', marginLeft: 'auto', marginRight: 'auto' }} data-testid="dynamicSection">
      <div
        style={{
          flex: 1,
          display: 'grid',
          ...gridStyle,
          position: 'relative'
        }}>
        {sortedBlocks.map((block, index) => {
          const previousLastMobileRow = lastMobileRow;
          const mobileBlockNumRows = getNumRowsFromBlock(block, isMobile);
          lastMobileRow = previousLastMobileRow + mobileBlockNumRows;
          return (
            <BlockWrapper
              key={block.guid}
              block={block}
              mobileOffset={previousLastMobileRow}
              mobileNumCols={mobileNumCols ?? MOBILE.NUM_COLS}>
              <Block block={block} editPath={`${editPath}.blocks[${index}]`} isPopup={isPopup} isMobile={isMobile} />
            </BlockWrapper>);
        }) }
      </div>
    </div>
  );
};

// The goal of this method is to scale the vertical size of the grid as the horizontal size
// changes. This maintains an aspect ratio that is similar to the original intent of the
// admin who set up their module. The original implementation of the grid only scaled
// horizontally - which will led to shapes and images having a compeltely different
// scale on various screen sizes that don't perfectly match the one shown in the editor
// when the admin set the site up.
const gridCSS = (
  numRows: number,
  numCols: number,
  isMobile: boolean,
  cellWidth?: string,
  cellHeight?: string
): React.CSSProperties => {
  if(cellWidth && cellHeight) {
    return {
      gridTemplateRows: `repeat(${numRows}, ${cellHeight})`,
      gridTemplateColumns: `repeat(${numCols}, ${cellWidth})`,
      gap: `${GRID_GAP}px`
    } as React.CSSProperties;
  }

  return {
    '--gutter': `${GRID_GAP}px`,
    '--max-site-width': '100%',
    '--cell-max-width': `calc( ( var(--max-site-width, 100vw) - (${GRID_GAP}px * ${
      numCols - 1
    }) ) / ${numCols } )`,
    gridTemplateRows: `repeat(${numRows}, minmax(${isMobile ? 'auto' : DESKTOP.CELL_LENGTH + 'px'}, 100%))`,
    gridTemplateColumns: `repeat(${numCols}, minmax(0, var(--cell-max-width)))`,
    gap: 'var(--gutter)',
    maxHeight: 'fit-content'
  } as React.CSSProperties;
};

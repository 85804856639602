import React, { useCallback, useMemo, useState } from 'react';
import { withRouter } from 'react-router';

import { useEditor } from '@toasttab/sites-components';

import { CtaType } from 'src/apollo/sites';
import { RequestContextProps } from 'src/lib/js/context';
import useTracker from 'src/lib/js/hooks/useTracker';
import { isToastLocalRequest } from 'src/public/js/siteUtilities';

import DropDown from 'shared/components/common/dropdown';
import { Modal, ModalCloseButton, ModalOverlay, useModal } from 'shared/components/common/modal';
import { useOptionalRestaurant, useRestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';
import { useRestaurantRoutes } from 'shared/components/common/restaurant_routes/RestaurantRoutesContext';
import { alertSuccess } from 'shared/js/alertUtils';

import { CTAData } from 'public/components/default_template/ctas';
import { DEFAULT_COLORS } from 'public/components/default_template/meta/StyleMeta';
import { useOptionalThemeColorScheme, useThemeColorScheme } from 'public/components/default_template/meta/useTheme';
import NavItems, { MobileNavItems } from 'public/components/default_template/nav/NavItems';
import { Logout } from 'public/components/default_template/online_ordering/account/logout/Logout';
import { PwlessAuth } from 'public/components/default_template/online_ordering/account/pwlessAuth/PwlessAuth';
import { AuthenticationSource } from 'public/components/default_template/online_ordering/checkout/checkoutUtils';
import { useCustomer } from 'public/components/online_ordering/CustomerContextCommon';

import LogoutIcon from './LogoutIcon';
import UserIcon from './UserIcon';
import { getNavColorStyles } from './navUtils';

const accountCtas = (accountPath: string, onClick: () => void): CTAData[] => [
  { nav: true, text: 'My account', type: CtaType.Link, link: accountPath, icon: <UserIcon color={'currentColor'} /> },
  { nav: true, text: 'Log out', type: 'customAction', onClick: onClick, icon: <LogoutIcon color={'currentColor'} /> }
];

type MobileUserNavItemsProps = {
  onOpen: () => void;
  onClose: () => void;
  setModalContent: (content: JSX.Element) => void;
  closeOverlay: () => void;
  color?: string | null;
};


export const MobileUserNavItems = ({ onOpen, onClose, setModalContent, closeOverlay, color }: MobileUserNavItemsProps) => {
  const { customer, pwlessLogout } = useCustomer();
  const restaurant = useOptionalRestaurant()?.restaurant;
  const { accountPath } = useRestaurantRoutes();
  const modalContentStyle = getNavColorStyles(restaurant, false);

  const openModal = useCallback(
    (content: JSX.Element) => () => {
      setModalContent(content);
      onOpen();
    },
    [onOpen, setModalContent]
  );

  const logout = useCallback(async () => {
    openModal(<Logout />)();
    const success = await pwlessLogout();
    if(success) {
      onClose();
      alertSuccess('You are now logged out');
    } else {
      setModalContent(<Logout error />);
    }
  },
  [openModal, onClose, setModalContent, pwlessLogout]);

  const customerCtas = useMemo((): CTAData[] => accountCtas(accountPath, logout), [accountPath, logout]);

  const loginCtas = useMemo(
    (): CTAData[] => [
      {
        nav: true, text: 'Log In / Sign Up', type: 'customAction', onClick: openModal(
          <PwlessAuth source={AuthenticationSource.UserNav} />
        ), icon: <UserIcon color={'currentColor'} />
      }
    ],
    [openModal]
  );

  return <MobileNavItems ctas={customer ? customerCtas : loginCtas} closeOverlay={closeOverlay} color={color || modalContentStyle.color} />;
};

const UserNav = ({ staticContext }: Pick<RequestContextProps, 'staticContext'>) => {
  const { customer, pwlessLogout } = useCustomer();
  const restaurant = useOptionalRestaurant()?.restaurant;
  const { accountPath } = useRestaurantRoutes();
  const getColorFromTheme = useOptionalThemeColorScheme();
  const { isOpen, onClose, onOpen } = useModal();
  const { isOpen: isLogoutOpen, onClose: logoutOnClose, onOpen: logoutOnOpen } = useModal();
  const [showOverlay, setShowOverlay] = useState(false);
  const tracker = useTracker();
  const isToastLocal = isToastLocalRequest(staticContext);

  const [logoutError, setLogoutError] = useState(false);
  const logout = useCallback(async () => {
    logoutOnOpen();
    const success = await pwlessLogout();
    if(success) {
      logoutOnClose();
      alertSuccess('You are now logged out');
    } else {
      setLogoutError(true);
    }
  },
  [logoutOnOpen, logoutOnClose, pwlessLogout]);

  const customerCtas = useMemo((): CTAData[] => accountCtas(accountPath, logout), [accountPath, logout]);

  const iconColor = getColorFromTheme(theme => {
    return theme.colorOverrides?.navigation?.text ?? theme.colorScheme.icon.default;
  }, restaurant?.content?.navConfig?.textColor || restaurant?.meta?.textColor || DEFAULT_COLORS.text);

  if(!customer) {
    return (
      <>
        <button
          className={isToastLocal ? 'userNavLoginBtn' : 'userNav' }
          data-testid="userNav"
          tabIndex={0}
          aria-label="Log in"
          onClick={() => {
            onOpen();
            tracker.track('Started Authentication', { source: AuthenticationSource.UserNav });
          }}>
          {isToastLocal ? 'Log In' : <UserIcon color={iconColor} />}
        </button>
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay fadeIn fadeOut />
          <PwlessAuth source={AuthenticationSource.UserNav} />
        </Modal>
      </>
    );
  }

  const modalContentStyle = getNavColorStyles(restaurant, false);

  return (
    <>
      <div className="hidden-sm-down">
        <div className="userNav" data-testid="userNav">
          <DropDown hideArrow ariaDescription="Account Actions" label={<UserIcon color={iconColor} />}>
            <NavItems ctas={customerCtas} />
          </DropDown>
          <Modal isOpen={isLogoutOpen} onClose={logoutOnClose}>
            <ModalOverlay fadeIn fadeOut />
            <Logout error={logoutError} />
          </Modal>
        </div>
      </div>
      <div className="hidden-md-up">
        <button className="userNav" aria-label="Account Actions" onClick={() => setShowOverlay(true)}>
          <UserIcon color={iconColor} />
        </button>
        {showOverlay
          ?
          <div className="mobileNavOverlay">
            <div className="overlay" onClick={() => setShowOverlay(false)} />
            <div className="content" style={modalContentStyle}>
              <div className="header">
                <ModalCloseButton onClose={() => setShowOverlay(false)} />
              </div>
              <div className="body">
                <div className="navLinks">
                  <MobileNavItems ctas={customerCtas} closeOverlay={() => setShowOverlay(false)} color={modalContentStyle.color} />
                </div>
              </div>
              <div className="footer" />
            </div>
          </div>
          : null}
      </div>
    </>
  );
};

const EditorUserNav = () => {
  const { restaurant } = useRestaurant();
  const getColorFromTheme = useThemeColorScheme();
  const iconColor = getColorFromTheme(theme => {
    return theme.colorOverrides?.navigation?.text ?? theme.colorScheme.icon.default;
  }, restaurant?.content?.navConfig?.textColor || restaurant?.meta?.textColor || DEFAULT_COLORS.text);
  return (
    <div className="userNav">
      <UserIcon color={iconColor} />
    </div>
  );
};

type UserNavProps = {
  forceDisplayToastLogin?: boolean | false;
};

const UserNavWithRouter = withRouter<UserNavProps & RequestContextProps, React.ComponentType<UserNavProps & RequestContextProps>>(UserNav);

const UserNavWrapper = (props: UserNavProps) => {
  const { isEditor } = useEditor();
  const restaurant = useOptionalRestaurant()?.restaurant;

  const navConfig = restaurant?.content?.navConfig;
  const shouldShowToastLogin = navConfig?.showToastLogin ?? true;

  if(!shouldShowToastLogin && !props.forceDisplayToastLogin) {
    return <></>;
  }
  return isEditor ? <EditorUserNav /> : <UserNavWithRouter />;
};

export default UserNavWrapper;

import React, { ReactNode, useMemo } from 'react';
import { withRouter } from 'react-router-dom';

import { useEditor } from '@toasttab/sites-components';

import { RequestContextProps } from 'src/lib/js/context';
import { isToastOrderRequest } from 'src/public/js/siteUtilities';

import Image from 'shared/components/common/Image';
import { useFlags } from 'shared/components/common/feature_flags/useFlags';
import Link from 'shared/components/common/link';
import { useRestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';


import { Locations } from './location/Locations';

type FooterProps = { children?: ReactNode };

type WrappedFooterProps = FooterProps & Pick<RequestContextProps, 'staticContext'>

const WrappedFooter = ({ children, staticContext }: WrappedFooterProps) => {
  const { restaurant } = useRestaurant();
  const { useEditableRef } = useEditor();
  const { intlOoCookieBanner } = useFlags();
  const { editableRef } = useEditableRef<HTMLDivElement>({ name: 'footer', displayName: 'Footer', actions: [], schema: { fields: [] } });
  const styles = useMemo(() => {
    const styles: React.CSSProperties = {};
    if(!restaurant?.theme?.enabled) {
      const nonThemedColors = restaurant?.content?.footerConfig?.colors;
      if(nonThemedColors?.background) {
        styles.backgroundColor = nonThemedColors.background;
      }
      if(nonThemedColors?.text) {
        styles.color = nonThemedColors.text;
      }
    }
    return styles;
  }, [restaurant]);

  if(!restaurant) {
    return null;
  }

  return (
    <>
      <div role="region" tabIndex={0} className="templateFooter" id="footer" ref={editableRef} style={styles}>
        <Locations />
        <div className="legal-container" role="contentinfo">
          <div className="poweredBy">
            This site is powered by
            <Link href="https://toasttab.com/local" blockAttribution aria-label="Toast logo">
              <Image aria-hidden="true" alt="Toast logo" src="icons/toast-logo-filled.svg" />
            </Link>
          </div>
          <ul className="legal">
            <li>
              <a href="https://pos.toasttab.com/terms-of-service/#diner-tos" rel="noopener noreferrer" target="_blank">
                Terms of Service
              </a>
            </li>
            <li>
              <span>|</span>
              <a href="https://pos.toasttab.com/privacy" rel="noopener noreferrer" target="_blank">
                Privacy Statement
              </a>
            </li>
            {isToastOrderRequest(staticContext) &&
            <li>
              <span>|</span>
              <a href="https://pos.toasttab.com/privacy#addendum-a" rel="noopener noreferrer" target="_blank">CA Privacy Statement</a>
            </li>}
            {intlOoCookieBanner &&
              <li>
                <span>|</span>
                <button id="ot-sdk-btn" className="ot-sdk-show-settings">Cookie Settings</button>
              </li>}
          </ul>
        </div>
        {children}
      </div>
    </>
  );
};

const FooterWithRouter = withRouter<FooterProps & RequestContextProps, React.ComponentType<FooterProps & RequestContextProps>>(WrappedFooter);

const Footer = (props: FooterProps) => {
  const { isEditor } = useEditor();

  if(isEditor) {
    return <WrappedFooter {...props } />;
  }

  return <FooterWithRouter {...props} />;
};

export default Footer;

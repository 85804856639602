import React, { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

import { ApolloQueryResult } from '@apollo/client';

import {
  CartFulfillmentInput, CartQuery,
  OrderSource,
  useAddToCartMutation,
  useApplyPromoCodeMutation,
  useCartQuery,
  useDeleteFromCartMutation,
  useEditCartItemMutation,
  useRemovePromoCodeMutation,
  useReorderMutation
} from 'src/apollo/onlineOrdering';
import { useThrottledTracker } from 'src/lib/js/hooks/useTracker';
import { useOOClient } from 'src/shared/components/common/oo_client_provider/OOClientProvider';

import { useRestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';

import { useSpi } from 'public/components/default_template/online_ordering/checkout/payment/useSpi';

import ToastProduct from './ToastProduct';
import { AddCartData, calculateSubtotal, Cart, CartData, modifierToSelectionInput, WrappedModifier, CartOrder } from './types';

export const getOrderSource = (toastProduct: ToastProduct) => {
  if(toastProduct === ToastProduct.ToastLocal) {
    return OrderSource.Local;
  } else if(toastProduct === ToastProduct.OOBasic) {
    return OrderSource.Online;
  }

  return OrderSource.Boo;
};


type ErrorType = 'CartModificationError' | 'CartOutOfStockError' | 'CartError' | 'ApplyPromoCodeError' | 'ReorderError' | 'ApolloError';
export type CartErrorType = {
  kind: ErrorType;
  message: string;
};

export type Surcharges = {
  [key:string]: {
    surchargeAmount?: number;
    surchargeRate?: number;
  }
} | null | undefined;

export type CartContextType = {
  cartGuid?: string | null;
  refetchCart: (variables?: { guid?: string|null, totalGiftCardBalance?: number|null } | null) => Promise<ApolloQueryResult<CartQuery>>;
  clearCart: () => void;
  addToCart: (item: WrappedModifier, quantity: number, fulfillmentData?: CartFulfillmentInput, trackData?: any) => Promise<null | ErrorType>;
  editCartItem: (item: WrappedModifier, quantity: number, selectionGuid: string) => Promise<null | ErrorType>;
  deleteFromCart: (itemGuid: string) => void;
  reorder: (orderGuid: string, restaurantGuid: string, fulfillment?: CartFulfillmentInput) => Promise<void>;
  subtotal?: number | null;
  applyPromoCode: (promoCode: string) => Promise<null | ErrorType>;
  removePromoCode: () => Promise<null | ErrorType>;
  loadingCart: boolean;
  cart?: Cart | null;
  restaurantGuid: string;
  error?: CartErrorType | null;
  surcharges: Surcharges;
  // timestamp of the last selection modification in the cart so we can react to changes
  lastOrderSelectionChange: Date;
}

export const CartContext = createContext<CartContextType | undefined>(undefined);

const getCartKey = (locationId: string): string => {
  return `toast-embedded-oo-cart|${locationId}`;
};

export type CartRestaurant = Cart['restaurant'] & { __typename: 'Restaurant' };
export type Location = CartRestaurant['location'];

export const CartContextProvider = (props: React.PropsWithChildren<{}>) => {
  const { selectedLocation, toastProduct } = useRestaurant();
  const [loading, setLoading] = useState(false);
  const [loadingCartGuid, setLoadingCartGuid] = useState(true);
  const [error, setError] = useState<null | CartErrorType>(null);
  const [cartGuid, setCartGuid] = useState<string | null>(null);
  const { track } = useThrottledTracker();
  const ooClient = useOOClient();
  const [addToCartMutation] = useAddToCartMutation({ client: ooClient });
  const [deleteFromCartMutation] = useDeleteFromCartMutation({ client: ooClient });
  const [editCartItemMutation] = useEditCartItemMutation({ client: ooClient });
  const [applyPromoCodeMutation] = useApplyPromoCodeMutation({ client: ooClient });
  const [removePromoCodeMutation] = useRemovePromoCodeMutation({ client: ooClient });
  const [reorderMutation] = useReorderMutation({ client: ooClient });
  const { spiSurchargingEnabled } = useSpi();
  const [lastOrderSelectionChange, setLastOrderSelectionChange] = useState<Date>(new Date());
  const lastOrderRef = useRef<CartOrder | null | undefined>(null);

  const restaurantGuid = selectedLocation.externalId;
  const cartKey = getCartKey(restaurantGuid);

  const clearCart = useCallback(() => {
    localStorage.setItem(cartKey, '');
    setCartGuid(null);
  }, [cartKey, setCartGuid]);

  useEffect(() => {
    if(typeof window !== 'undefined') {
      const cartGuid = localStorage.getItem(cartKey);
      if(cartGuid) {
        setCartGuid(cartGuid);
      } else {
        setCartGuid(null);
      }
      setLoadingCartGuid(false);
    }
  }, [cartKey]);

  const { data, loading: loadingData, error: reqError, refetch } = useCartQuery({
    ssr: false,
    skip: !cartGuid,
    variables: { guid: cartGuid || '' },
    // notifyOnNetworkStatusChange: true,
    client: ooClient
  });

  useEffect(() => {
    if(reqError) {
      setError({
        kind: 'ApolloError',
        message: reqError.message
      });
    }
  }, [reqError]);

  if(data?.cartV2?.__typename === 'CartError') {
    setError({
      kind: data?.cartV2.__typename,
      message: data?.cartV2.message
    });
    clearCart();
  }

  const cartData: CartData = data?.cartV2 as CartData;

  useEffect(() => {
    const order = cartData?.cart?.order;
    if(hasOrderSelectionChanged(order, lastOrderRef.current)) {
      lastOrderRef.current = order;
      setLastOrderSelectionChange(new Date());
    }
  }, [cartData?.cart?.order]);

  const surcharges: Surcharges = useMemo(() => {
    if(spiSurchargingEnabled && cartData?.cart?.order?.surcharges) {
      return cartData.cart.order.surcharges.reduce((acc: {
        [key: string]: { surchargeAmount: number, surchargeRate: number }
      }, surchargeSummary) => {
        surchargeSummary?.paymentTypes?.forEach((paymentType: string) => {
          if(surchargeSummary.surchargePreTaxAmount) {
            acc[paymentType] = {
              surchargeAmount: surchargeSummary.surchargePreTaxAmount + surchargeSummary.surchargeTaxAmount,
              surchargeRate: surchargeSummary.surchargeRate
            };
          }
        });
        return acc;
      }, {});
    }
    return null;
  }, [cartData?.cart?.order?.surcharges, spiSurchargingEnabled]);

  const addToCart = useCallback(async (item: WrappedModifier, quantity: number, fulfillmentData?: CartFulfillmentInput, trackData?: any) => {
    // this should never happen, since we don't show the cart if ordering is unavailable
    if(!fulfillmentData) {
      return null;
    }

    setLoading(true);
    const { data } = await addToCartMutation({
      variables: {
        input: {
          cartGuid,
          createCartInput: cartGuid
            ? null
            : {
              restaurantGuid: restaurantGuid,
              orderSource: getOrderSource(toastProduct),
              cartFulfillmentInput: fulfillmentData
            },
          selection: modifierToSelectionInput({
            ...item,
            quantity
          })
        }
      }
    });

    if(!cartGuid && data?.addItemToCartV2?.__typename === 'CartResponse') {
      const cartData: AddCartData = data?.addItemToCartV2 as AddCartData;
      localStorage.setItem(cartKey, cartData.cart.guid);
      setCartGuid(cartData.cart.guid);
    }

    if(cartGuid) {
      refetch();
    }
    setLoading(false);

    if(data?.addItemToCartV2?.__typename === 'CartModificationError' || data?.addItemToCartV2?.__typename === 'CartOutOfStockError') {
      setError({
        kind: data?.addItemToCartV2.__typename,
        message: data?.addItemToCartV2?.message
      });
      return data?.addItemToCartV2?.__typename;
    }

    const { cart: { cartSource } } = data?.addItemToCartV2 as AddCartData;

    track('Added item to cart', {
      ...trackData,
      cartSource
    });

    return null;
  }, [track, cartGuid, addToCartMutation, restaurantGuid, refetch, cartKey, toastProduct]);

  const editCartItem = useCallback(async (item: WrappedModifier, quantity: number, selectionGuid: string) => {
    // this shouldn't be possible
    if(!cartGuid) {
      return null;
    }

    setLoading(true);
    const { data } = await editCartItemMutation({
      variables: {
        input: {
          cartGuid,
          selectionGuid,
          selection: modifierToSelectionInput({
            ...item,
            quantity
          })
        }
      }
    });

    refetch();
    setLoading(false);
    if(data?.editItemInCartV2.__typename === 'CartModificationError' || data?.editItemInCartV2?.__typename === 'CartOutOfStockError') {
      setError({
        kind: data?.editItemInCartV2.__typename,
        message: data?.editItemInCartV2?.message
      });
      return data?.editItemInCartV2?.__typename;
    }

    return null;
  }, [cartGuid, editCartItemMutation, refetch]);

  const deleteFromCart = useCallback(async (itemGuid: string) => {
    if(!cartGuid) {
      return;
    }

    setLoading(true);
    await deleteFromCartMutation({
      variables: {
        input: {
          cartGuid,
          selectionGuid: itemGuid
        }
      }
    });

    // Not necessary, could be replaced with cart returned from mutation
    refetch();
    setLoading(false);
  }, [cartGuid, deleteFromCartMutation, refetch]);

  const applyPromoCode = useCallback(async (promoCode: string) => {
    // this shouldn't be possible
    if(!cartGuid) {
      return null;
    }

    setLoading(true);
    const { data } = await applyPromoCodeMutation({ variables: { input: { cartGuid, promoCode } } });

    if(data?.applyPromoCodeV3.__typename === 'ApplyPromoCodeError') {
      setLoading(false);
      return data?.applyPromoCodeV3.__typename;
    }

    refetch();
    setLoading(false);
    return null;
  }, [cartGuid, applyPromoCodeMutation, refetch]);

  const removePromoCode = useCallback(async () => {
    // this shouldn't be possible
    if(!cartGuid) {
      return null;
    }

    setLoading(true);
    const { data } = await removePromoCodeMutation({ variables: { input: { cartGuid } } });

    if(data?.removePromoCodeV2.__typename === 'CartModificationError' || data?.removePromoCodeV2.__typename === 'CartOutOfStockError' ) {
      setLoading(false);
      return data?.removePromoCodeV2.__typename;
    }

    refetch();
    setLoading(false);
    return null;
  }, [cartGuid, removePromoCodeMutation, refetch]);

  const reorder = useCallback(async (orderGuid: string, restaurantGuid: string, fulfillment?: CartFulfillmentInput) => {
    setLoading(true);

    const { data } = await reorderMutation({
      variables: {
        input: {
          orderGuid,
          restaurantGuid,
          cartFulfillmentInput: fulfillment,
          orderSource: getOrderSource(toastProduct)
        }
      }
    });

    if(data?.reorderV2?.__typename === 'ReorderResponse') {
      localStorage.setItem(cartKey, data.reorderV2.cart.guid);
      setCartGuid(data.reorderV2.cart.guid);
    }

    if(data?.reorderV2?.__typename === 'ReorderError') {
      setError({
        kind: data?.reorderV2.__typename,
        message: data?.reorderV2?.message
      });
    }

    setLoading(false);
    return;
  }, [reorderMutation, cartKey, toastProduct]);

  const subtotal = useMemo(() => calculateSubtotal(cartData?.cart?.order), [cartData]);

  return (
    <CartContext.Provider value={{
      cartGuid,
      refetchCart: refetch,
      clearCart,
      addToCart,
      deleteFromCart,
      editCartItem,
      reorder,
      subtotal,
      applyPromoCode,
      removePromoCode,
      loadingCart: loading || loadingCartGuid || loadingData,
      cart: cartData?.cart,
      restaurantGuid: restaurantGuid,
      error,
      surcharges,
      lastOrderSelectionChange
    }}>
      {props.children}
    </CartContext.Provider>);
};

export const useCart = () => {
  const context = useContext(CartContext);
  if(!context) {
    throw new Error('useCart must be used within a CartContextProvider');
  }

  return context;
};

export const useOptionalCart = () => {
  const context = useContext(CartContext);

  return context;
};

const hasOrderSelectionChanged = (order?: CartOrder, lastOrder?: CartOrder): boolean => {
  // We can rely on length to short-circuit the comparison because you can not add and remove selections in the same operation
  // Length covers adding, removing and quantity changes while preDiscountItemsSubtotals covers quantity and modifier changes
  return order?.numberOfSelections !== lastOrder?.numberOfSelections
    || order?.preDiscountItemsSubtotal !== lastOrder?.preDiscountItemsSubtotal;
};

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { RotatingLines } from 'react-loader-spinner';

import { isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js';
import isEmail from 'validator/lib/isEmail';

import { DiningOptionBehavior } from 'src/apollo/onlineOrdering';
import { useIsIntlRestaurant } from 'src/lib/js/hooks/useIsIntlRestaurant';
import useTracker from 'src/lib/js/hooks/useTracker';
import { LoginStage, PwlessAuth } from 'src/public/components/default_template/online_ordering/account/pwlessAuth/PwlessAuth';
import { CAInfo, ToastTOSAndPrivacy } from 'src/public/components/default_template/online_ordering/account/pwlessAuth/legalUtils';
import { useMarketing } from 'src/public/components/online_ordering/MarketingContext';
import { ukiDummyAddressValue } from 'src/public/components/online_ordering/addressUtils';
import { useFlags } from 'src/shared/components/common/feature_flags/useFlags';
import InputField from 'src/shared/components/common/form_input/InputField';
import { InternationalPhoneInput } from 'src/shared/components/common/form_input/InternationalPhoneInput';
import PhoneInput from 'src/shared/components/common/form_input/PhoneInput';
import { Modal, ModalOverlay, useModal } from 'src/shared/components/common/modal';
import { useRestaurant } from 'src/shared/components/common/restaurant_context/RestaurantContext';
import { ShowForUS } from 'src/shared/components/common/show_for_us/ShowForUS';
import { asValidPhoneCountryCode } from 'src/shared/js/phoneUtils';

import Image from 'shared/components/common/Image';
import { ToggleInput } from 'shared/components/common/forms';

import AnimatedSection from 'public/components/default_template/online_ordering/checkout/AnimatedSection';
import CheckoutSection from 'public/components/default_template/online_ordering/checkout/CheckoutSection';
import { useCart } from 'public/components/online_ordering/CartContext';
import { useCheckout } from 'public/components/online_ordering/CheckoutContext';
import { useCustomer } from 'public/components/online_ordering/CustomerContextCommon';
import { useDelivery } from 'public/components/online_ordering/DeliveryContext';
import { PaymentOption, usePayment } from 'public/components/online_ordering/PaymentContext';

import { AuthenticationSource, customerHasAllValidInfo, getCustomerInfo } from './checkoutUtils';
import { MarketingSubscriptionCheckbox, MarketingSubscriptionCheckboxLegalText } from './marketing/MarketingSubscriptionCheck';


export const PHONE_FOCUS_MSG = 'By providing a mobile number, you give Toast permission to contact you using automated text messages to provide transactional messages such as order status updates.';

export const CustomerInfoSection = () => {
  const { customer } = useCustomer();
  const [editing, setEditing] = useState(false);
  const { paymentOption, userInfoRequired } = usePayment();
  const { ooRestaurant } = useRestaurant();
  const { intlOoPhoneCountryDropDown } = useFlags();
  const restaurantCountryCode = !intlOoPhoneCountryDropDown ? asValidPhoneCountryCode(ooRestaurant?.i18n?.country) : undefined; // only use rx country if FF is disabled

  const required = useMemo(
    () =>
      paymentOption === PaymentOption.UponReceipt
      || paymentOption !== PaymentOption.ApplePay && userInfoRequired,
    [paymentOption, userInfoRequired]
  );

  // Check if the customer has all the required info and that info is valid in the restaurant country.
  // When we support the tourist use case, we should be able to remove this as all info should be in a format which is valid in all supported countries.
  const isCustomerInfoValid = useMemo(() => customerHasAllValidInfo(customer, restaurantCountryCode), [customer, restaurantCountryCode]);

  const onKeyDown = React.useCallback((e: React.KeyboardEvent) => {
    switch(e.key) {
      case ' ':
      case 'Enter':
        setEditing(!editing);
        break;
    }
  }, [editing, setEditing]);

  return (
    <CheckoutSection>
      <AnimatedSection expanded={Boolean(customer) || required} slowTransition>
        <div data-testid={customer ? 'toast-checkout-inputs' : 'guest-checkout-inputs'} role="form">
          <div className="yourInfoContainer">
            <div className="yourInfoHeader">
              <h2 className="checkoutSectionHeader">Your info</h2>
              {customer && !editing && <div tabIndex={0} className="editLink" aria-label="Edit your user info" role="button" onKeyDown={onKeyDown} onClick={() => setEditing(true)}>Edit</div>}
            </div>
            <AnimatedSection expanded={isCustomerInfoValid && !editing} slowTransition testid="completed-info-animated-section">
              {isCustomerInfoValid && !editing && <CompletedInfo />}
            </AnimatedSection>
            <AnimatedSection expanded={!isCustomerInfoValid || editing} slowTransition testid="customer-info-inputs-animated-section">
              { (!isCustomerInfoValid || editing) && <CustomerInfoInputs initialFocus={!!customer} required={required} /> }
            </AnimatedSection>
            <div className="updateLinkContainer">
              {
              // Logically structure update at the end for a11y tab ordering
                customer && editing && <div tabIndex={0} className="editLink" aria-label="Update your user info" role="button" onKeyDown={onKeyDown} onClick={() => setEditing(false)}>Update</div>
              }
            </div>
          </div>
        </div>
      </AnimatedSection>
    </CheckoutSection>
  );
};

interface CustomerInfoInputsProps {
  initialFocus?: boolean;
  required: boolean
}

const CustomerInfoInputs = ({ initialFocus = false, required }: CustomerInfoInputsProps) => {
  const { createAccount, setCreateAccount, saveNewAddress, setSaveNewAddress } = useCheckout();
  const { smsAccountEnabled, subscribeToSmsMarketing, setSubscribeToSmsMarketing, subscribeToEmailMarketing, setSubscribeToEmailMarketing } = useMarketing();
  const { customer, passwordlessLogin } = useCustomer();
  const tracker = useTracker();
  const { formState: { errors }, setFocus, clearErrors, trigger } = useFormContext();
  const { cart } = useCart();
  const { savedAddressUsed } = useDelivery();
  const [deliveryAddressLine2, setDeliveryLineAddress2] = useState(cart?.order?.deliveryInfo?.address2);
  const [deliveryNotes, setDeliveryNotes] = useState(cart?.order?.deliveryInfo?.notes);
  const [zipcode, setZipCode] = useState(cart?.order?.deliveryInfo?.zipCode === ukiDummyAddressValue ? '' : cart?.order?.deliveryInfo?.zipCode);
  const { ooRestaurant } = useRestaurant();
  const { ooEmailSubscriptionCheckboxEnabled, intlOoPhoneCountryDropDown } = useFlags();
  const { isOpen: isAuthModalOpen, onClose: onCloseAuthModal, onOpen: onOpenAuthModal } = useModal();
  const [isLoadingPwlessLogin, setIsLoadingPwlessLogin] = useState(false);
  const isIntlRestaurant = useIsIntlRestaurant();

  useEffect(() => {
    if(cart?.diningOptionBehavior !== DiningOptionBehavior.Delivery) {
      setSaveNewAddress(false);
    }
    setDeliveryLineAddress2(cart?.order?.deliveryInfo?.address2);
    setDeliveryNotes(cart?.order?.deliveryInfo?.notes);
  }, [setSaveNewAddress, cart]);

  useEffect(() => {
    if(saveNewAddress) {
      // forcing the focus here makes sure the validation for this field gets run
      setFocus('addressLabel');
    } else {
      // clear any errors for this required field when it isnt visible
      clearErrors('addressLabel');
    }
  }, [saveNewAddress, setFocus, clearErrors]);

  const handleCreateAccountCheckboxChange = async (checked: boolean) => {
    setCreateAccount(checked);
    if(checked) {
      tracker.track('Started Authentication', { source: AuthenticationSource.AccountCreation });
      setIsLoadingPwlessLogin(true);
      await sendPwlessLoginCode();
      onOpenAuthModal();
      setIsLoadingPwlessLogin(false);
      tracker.track('Clicked Send code', { source: AuthenticationSource.AccountCreation });
    }
  };

  const phoneNumber = useWatch({ name: 'yourInfoPhone' });
  const email = useWatch({ name: 'yourInfoEmail' });
  const firstName = useWatch({ name: 'yourInfoFirstName' });
  const lastName = useWatch({ name: 'yourInfoLastName' });

  const sendPwlessLoginCode = useCallback( async () => {
    await passwordlessLogin(phoneNumber);
  }, [phoneNumber, passwordlessLogin]);

  const rxCountryCode = asValidPhoneCountryCode(ooRestaurant?.i18n.country);
  const validatePhone = useCallback(
    (value: string) => !intlOoPhoneCountryDropDown && required && !isValidPhoneNumber(value, rxCountryCode) ? 'enter a valid local phone number' : true,
    [intlOoPhoneCountryDropDown, required, rxCountryCode]
  );

  const validateEmail = useCallback(
    (value: string) => required && !isEmail(value) ? 'enter a valid email address' : true,
    [required]
  );

  useEffect(() => {
    if(!required) {
      // clear errors and trigger validation so that the form can be submitted when these fields are no longer required
      clearErrors(['yourInfoPhone', 'yourInfoEmail', 'yourInfoFirstName', 'yourInfoLastName']);
      trigger(['yourInfoPhone', 'yourInfoEmail', 'yourInfoFirstName', 'yourInfoLastName']);
    }
  }, [required, clearErrors, trigger]);

  const onCloseAuthModalCb = useCallback(() => {
    onCloseAuthModal();
    if(!customer) {
      setCreateAccount(false);
    }
  }, [customer, onCloseAuthModal, setCreateAccount]);

  const defaultCustomerValues = getCustomerInfo(customer);
  const isIrishRx = cart?.order?.deliveryInfo?.country === 'IE';
  const isGbRx = cart?.order?.deliveryInfo?.country === 'GB';

  return (
    <>
      {intlOoPhoneCountryDropDown ?
        <InternationalPhoneInput
          value={defaultCustomerValues.yourInfoPhone || phoneNumber}
          required={required}
          label="Phone number"
          id="yourInfoPhone"
          initialFocus={initialFocus}
          unregisterOnUnmount={false} />
        :
        <PhoneInput
          id="yourInfoPhone"
          initialFocus={initialFocus}
          required={required}
          label="Phone number"
          validate={validatePhone}
          autoComplete="tel"
          defaultValue={defaultCustomerValues.yourInfoPhone || phoneNumber}
          filled={Boolean(customer?.phone)}
          unregisterOnUnmount={false} />}
      <InputField id="yourInfoEmail" type="email" autoComplete="email" label="Email" defaultValue={defaultCustomerValues.yourInfoEmail}
        required={required} validate={validateEmail} />
      <InputField id="yourInfoFirstName" type="text" autoComplete="given-name" label="First name" defaultValue={defaultCustomerValues.yourInfoFirstName}
        required={required} />
      <InputField id="yourInfoLastName" type="text" autoComplete="family-name" label="Last name" defaultValue={defaultCustomerValues.yourInfoLastName}
        required={required} />
      {cart?.diningOptionBehavior === DiningOptionBehavior.Delivery &&
        <>
          {(isIrishRx || isGbRx) &&
          <InputField id="deliveryZipCode" type="text" label={isIrishRx ? 'Eircode' : 'Postcode' } autoComplete="zipcode"
            value={zipcode || ''} required={required} onChange={e => { setZipCode(e.target.value ); }} />}
          <InputField id="deliveryAddress2" type="text" label="Apt./Suite no." autoComplete="address-line2"
            value={deliveryAddressLine2 || ''} onChange={e => { setDeliveryLineAddress2(e.target.value ); }} />
          <InputField id="deliveryInstructions" type="text" label="Delivery Instructions"
            value={deliveryNotes || ''} onChange={e => { setDeliveryNotes(e.target.value ); }} />
          {customer && !savedAddressUsed &&
              <div className="inputField"
                data-testid="save-new-address">
                <ToggleInput
                  type="checkbox"
                  name="saveNewAddress"
                  id="saveNewAddress"
                  checked={saveNewAddress}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSaveNewAddress(event.target.checked)}>
                      Save address
                </ToggleInput>
              </div>}
          <AnimatedSection expanded={saveNewAddress}>
            <InputField id="addressLabel" type="text" label="Address label" defaultValue="" required={saveNewAddress} />
          </AnimatedSection>
        </>}
      {!customer &&
        <div className="createAccountCheckbox">
          <ShowForUS>
            <div className="checkboxContainer">
              {isLoadingPwlessLogin ?
                <div className="loadingWrapper" data-testid="customer-info-loader">
                  <RotatingLines
                    width="20"
                    visible
                    strokeColor="#888888"
                    strokeWidth="3" />
                </div> :
                <ToggleInput
                  type="checkbox"
                  name="createToastAccount"
                  id="createToastAccount"
                  dataTestId="createToastAccount"
                  ariaLabel="createAccountLabel"
                  disabled={!(phoneNumber && email && firstName && lastName &&
                !Object.keys(errors).some(field => ['yourInfoPhone', 'yourInfoEmail', 'yourInfoFirstName', 'yourInfoLastName'].includes(field)))}
                  checked={createAccount}
                  onChange={async (event: React.ChangeEvent<HTMLInputElement>) => await handleCreateAccountCheckboxChange(event.target.checked)}
                  onKeyDown={async (event: React.KeyboardEvent<HTMLInputElement>) => {
                    if(event.key === 'Enter') {
                      event.preventDefault();
                      event.stopPropagation();
                      await handleCreateAccountCheckboxChange(!createAccount);
                    }
                  }} />}
              <div className="createAccountLabel" id="createAccountLabel">
              Create a Toast Account for quicker checkout next time.
                <div className="imageContainer">
                  <Image alt="Toast logo" aria-hidden="true" src="icons/toast-logo-filled.svg" />
                </div>
              </div>
            </div>
            <div className="note" role="contentinfo">
            By checking this box, you give Toast permission to send you a one-time verification code to the mobile number
            provided to set up your account. Message and data rates may apply. Subject to <ToastTOSAndPrivacy />. {!isIntlRestaurant && <CAInfo />}
            </div>
          </ShowForUS>
        </div>}
      {!customer && smsAccountEnabled &&
        <MarketingSubscriptionCheckbox
          name="subscribeToSmsMarketing"
          legalText={MarketingSubscriptionCheckboxLegalText.SMS}
          isActive={subscribeToSmsMarketing}
          setActive={setSubscribeToSmsMarketing}
          disabled={!(phoneNumber && !Object.keys(errors).includes('yourInfoPhone'))} />}
      {!customer && ooEmailSubscriptionCheckboxEnabled &&
        <MarketingSubscriptionCheckbox
          name="subscribeToEmailMarketing"
          legalText={MarketingSubscriptionCheckboxLegalText.EMAIL}
          isActive={subscribeToEmailMarketing}
          setActive={setSubscribeToEmailMarketing} />}
      <Modal isOpen={isAuthModalOpen} onClose={onCloseAuthModalCb}>
        <ModalOverlay fadeIn fadeOut />
        <PwlessAuth
          source={AuthenticationSource.AccountCreation}
          currentLoginStage={LoginStage.EnterVerificationCode}
          showBackButton={false}
          accountInfo={{ phoneNumber, email, firstName, lastName }}
          onSuccessfulLogin={() => window.scrollTo(0, 0)} />
      </Modal>
    </>
  );
};

/** Renders the customer's info in a compact format after it's been entered and validated */
const CompletedInfo = () => {
  const { smsAccountEnabled, subscribeToSmsMarketing, setSubscribeToSmsMarketing, subscribeToEmailMarketing, setSubscribeToEmailMarketing } = useMarketing();
  const { ooEmailSubscriptionCheckboxEnabled, intlOoPhoneCountryDropDown } = useFlags();
  const phoneNumber = useWatch({ name: 'yourInfoPhone' });
  const email = useWatch({ name: 'yourInfoEmail' });
  const firstName = useWatch({ name: 'yourInfoFirstName' });
  const lastName = useWatch({ name: 'yourInfoLastName' });
  const addressLine2 = useWatch({ name: 'deliveryAddress2' });
  const deliveryInstructions = useWatch({ name: 'deliveryInstructions' });
  const { ooRestaurant } = useRestaurant();
  const rxCountryCode = asValidPhoneCountryCode(ooRestaurant?.i18n.country);
  const parsedPhoneNumber = useMemo(() => {
    const country = !intlOoPhoneCountryDropDown ? rxCountryCode : undefined;

    return isValidPhoneNumber(phoneNumber ?? '', country)
      ? parsePhoneNumber(phoneNumber, country)
      : null;
  }, [intlOoPhoneCountryDropDown, phoneNumber, rxCountryCode]);

  return (
    <div className="completedInfo">
      {firstName && lastName && <div className="sectionRow"><div className="icon"><Image alt="Customer icon" src="icons/user-gray.svg" /></div>{firstName} {lastName}</div>}
      {email && <div className="sectionRow"><div className="icon"><Image alt="Email icon" src="icons/email-gray.svg" /></div>{email}</div>}
      {parsedPhoneNumber?.isValid() &&
          <div className="sectionRow"><div className="icon"><Image alt="Phone icon" src="icons/phone-gray.svg" /></div>{intlOoPhoneCountryDropDown
            ? parsedPhoneNumber.formatInternational()
            : parsedPhoneNumber.formatNational()}
          </div>}
      {addressLine2 && <div className="sectionRow"><div className="icon"><Image alt="Location icon" src="icons/location.svg" /></div>{addressLine2}</div>}
      {deliveryInstructions && <div className="sectionRow"><div className="icon"><Image alt="Location icon" src="icons/location.svg" /></div>{deliveryInstructions}</div>}
      {parsedPhoneNumber?.isValid() && smsAccountEnabled &&
        <MarketingSubscriptionCheckbox
          name="subscribeToSmsMarketing"
          legalText={MarketingSubscriptionCheckboxLegalText.SMS}
          isActive={subscribeToSmsMarketing}
          setActive={setSubscribeToSmsMarketing} />}
      {ooEmailSubscriptionCheckboxEnabled && <MarketingSubscriptionCheckbox
        name="subscribeToEmailMarketing"
        legalText={MarketingSubscriptionCheckboxLegalText.EMAIL}
        isActive={subscribeToEmailMarketing}
        setActive={setSubscribeToEmailMarketing} />}
    </div>
  );
};

export default CustomerInfoSection;


      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "AcceptSocialFollowersResult": [
      "AcceptSocialFollowersSuccess",
      "InvalidSocialAcceptFollowRequestError",
      "SocialUserDoesNotExistError",
      "UnexpectedError"
    ],
    "AddGuestBirthdayResult": [
      "AddGuestBirthdaySuccess",
      "BirthdayAlreadyExistsError",
      "InvalidBirthdayError",
      "UnexpectedError"
    ],
    "AddGuestIdentityResult": [
      "AddGuestIdentitySuccess",
      "GuestIdentityAlreadyExistsForCurrentGuestError",
      "InvalidEmailError",
      "ProfileLockedForDeletionError",
      "UnexpectedError"
    ],
    "AddGuestReactionToPostResponse": [
      "AddGuestReactionToPostSuccess",
      "UnexpectedError"
    ],
    "ApplyCodeToGuestCurrencyAccountResult": [
      "ApplyCodeToGuestCurrencyAccountError",
      "ApplyCodeToGuestCurrencyAccountSuccess"
    ],
    "ApplyPromoCodeResponseOrError": [
      "ApplyPromoCodeError",
      "ApplyPromoCodeResponse"
    ],
    "ApplyPromoCodeResponseOrErrorV2": [
      "ApplyPromoCodeError",
      "CartModificationError",
      "CartOutOfStockError",
      "CartResponse"
    ],
    "ArchiveGuestPostResult": [
      "ArchiveGuestPostSuccess",
      "UnexpectedError"
    ],
    "ArchiveGuestReviewResult": [
      "ArchiveGuestReviewSuccess",
      "UnexpectedError"
    ],
    "AuthorizePaymentResponseOrError": [
      "AuthorizePaymentError",
      "AuthorizePaymentResponse"
    ],
    "BanSocialUserResult": [
      "BanSocialUserSuccess",
      "SocialUserBannedError",
      "SocialUserDoesNotExistError",
      "UnexpectedError"
    ],
    "BookingCancelReservationResponse": [
      "BookingCancelReservationResponseSuccess",
      "BookingInternalError",
      "BookingNotFound",
      "BookingRestaurantNotFound",
      "BookingUnableToRefundDeposit",
      "BookingUnauthorizedGuestProfile",
      "BookingUnsupportedTransition"
    ],
    "BookingCreateReservationResponse": [
      "BookingCreateReservationResponseSuccess",
      "BookingDepositAmountChanged",
      "BookingDepositRuleNotMatching",
      "BookingInvalidEmail",
      "BookingInvalidPartySize",
      "BookingInvalidPhoneNumber",
      "BookingNoTableAvailable",
      "BookingOverlappingBooking",
      "BookingRequiresDepositData",
      "BookingRestaurantIsClosed",
      "BookingRestaurantNotFound",
      "BookingServiceAreaNotFound",
      "BookingUnableToCreateDepositOrder",
      "BookingUnableToCreateGuest"
    ],
    "BookingCreateWaitlistResponse": [
      "BookingCreateReservationResponseSuccess",
      "BookingInvalidEmail",
      "BookingInvalidPartySize",
      "BookingInvalidPhoneNumber",
      "BookingRestaurantIsClosed",
      "BookingRestaurantNotFound",
      "BookingServiceAreaNotFound",
      "BookingUnableToCreateGuest"
    ],
    "BookingDepositStrategy": [
      "BookingDepositStrategyByBooking",
      "BookingDepositStrategyByPartySize"
    ],
    "BookingError": [
      "BookingBulkAvailabilitiesRestaurantLimitExceeded",
      "BookingDepositAmountChanged",
      "BookingDepositRuleNotMatching",
      "BookingGuestNotFound",
      "BookingInternalError",
      "BookingInvalidBooking",
      "BookingInvalidEmail",
      "BookingInvalidPartySize",
      "BookingInvalidPhoneNumber",
      "BookingNoTableAvailable",
      "BookingNotFound",
      "BookingOverlappingBooking",
      "BookingRequiresDepositData",
      "BookingRestaurantIsClosed",
      "BookingRestaurantNotFound",
      "BookingServiceAreaNotFound",
      "BookingUnableToCreateDepositOrder",
      "BookingUnableToCreateGuest",
      "BookingUnableToFetchGuests",
      "BookingUnableToRefundDeposit",
      "BookingUnableToSendSMS",
      "BookingUnableToUpdateReservation",
      "BookingUnauthorizedGuestProfile",
      "BookingUnsupportedTransition"
    ],
    "BookingGuestBookingsResponse": [
      "BookingGuestBookingsResponseSuccess",
      "BookingInternalError"
    ],
    "BookingPublicAvailabilitiesResponse": [
      "BookingNotFound",
      "BookingPublicAvailabilitiesResponseSuccess",
      "BookingRestaurantNotFound"
    ],
    "BookingPublicBookingResponse": [
      "BookingInternalError",
      "BookingNotFound",
      "BookingPublicBookingResponseSuccess",
      "BookingRestaurantNotFound",
      "BookingUnableToFetchGuests"
    ],
    "BookingPublicBulkAvailabilitiesErrorResponse": [
      "BookingNotFound",
      "BookingRestaurantNotFound"
    ],
    "BookingPublicBulkAvailabilitiesResponse": [
      "BookingBulkAvailabilitiesRestaurantLimitExceeded",
      "BookingPublicBulkAvailabilitiesError",
      "BookingPublicBulkAvailabilitiesResponseSuccess"
    ],
    "BookingPublicRestaurantResponse": [
      "BookingInternalError",
      "BookingPublicRestaurantResponseSuccess",
      "BookingRestaurantNotFound"
    ],
    "BookingPublicWaitlistEstimateResponse": [
      "BookingInternalError",
      "BookingInvalidBooking",
      "BookingNotFound",
      "BookingPublicWaitlistEstimateResponseSuccess",
      "BookingRestaurantIsClosed",
      "BookingRestaurantNotFound"
    ],
    "BookingUpdateReservationResponse": [
      "BookingInvalidEmail",
      "BookingInvalidPhoneNumber",
      "BookingNotFound",
      "BookingOverlappingBooking",
      "BookingRestaurantIsClosed",
      "BookingUnableToCreateGuest",
      "BookingUnableToSendSMS",
      "BookingUnableToUpdateReservation",
      "BookingUnauthorizedGuestProfile",
      "BookingUpdateReservationResponseSuccess"
    ],
    "BookingUpdateWaitlistResponse": [
      "BookingNotFound",
      "BookingRestaurantIsClosed",
      "BookingRestaurantNotFound",
      "BookingUnableToSendSMS",
      "BookingUpdateWaitlistResponseSuccess"
    ],
    "CON_EnrollInIndustryPassResult": [
      "CON_EnrollInIndustryPassError",
      "CON_EnrollInIndustryPassSuccess"
    ],
    "CON_IndustryPassStatus": [
      "CON_IndustryPassEligible",
      "CON_IndustryPassEnrolled",
      "CON_IndustryPassIneligible"
    ],
    "CON_ToastCashbackRestaurantsResult": [
      "CON_ToastCashbackInvalidInputError",
      "CON_ToastCashbackProgramNotFoundError",
      "CON_ToastCashbackRestaurants"
    ],
    "CON_ToastCashbackRewardResult": [
      "CON_ToastCashbackAlreadyIssuedReward",
      "CON_ToastCashbackInvalidOrder",
      "CON_ToastCashbackReward",
      "CON_ToastCashbackRewardFailed"
    ],
    "CartModificationResponseOrError": [
      "CartModificationError",
      "CartOutOfStockError",
      "CartResponse",
      "CartValidationError"
    ],
    "CartResponseOrError": [
      "CartError",
      "CartResponse"
    ],
    "CompleteIdentityProfileResponseOrError": [
      "CompleteIdentityProfileError",
      "CompleteIdentityProfileResponse"
    ],
    "CompleteProfileCreationResponseOrError": [
      "CompleteProfileCreationError",
      "CompleteProfileCreationResponse"
    ],
    "ConfirmGuestIdentityResult": [
      "CodeAuthenticationError",
      "CodeExpiredError",
      "CodeMaxAttemptsReachedError",
      "ConfirmGuestIdentitySuccess",
      "GuestIdentityAlreadyExistsForAnotherGuestError",
      "IncorrectCodeError",
      "UnexpectedConfirmCodeError"
    ],
    "ConfirmGuestResult": [
      "CompleteGuestProfileInputRequiredError",
      "ConfirmGuestSuccess",
      "InvalidEmailError",
      "InvalidNameError",
      "ProfileLockedForDeletionError",
      "UnexpectedAuthError"
    ],
    "CreateAccountResponseOrError": [
      "CreateAccountError",
      "CreateAccountResponse"
    ],
    "CreateConfirmedAccountResponseOrError": [
      "AuthenticationResponse",
      "CreateAccountError"
    ],
    "CreateCreditCardPreauthResponse": [
      "CreateCreditCardPreauthAuthorizedResponse",
      "CreateCreditCardPreauthDeniedResponse"
    ],
    "CreateMarketingSubscriptionRequestResponse": [
      "CreateMarketingSubscriptionRequestError",
      "CreateMarketingSubscriptionRequestSuccessResponse"
    ],
    "CreateOrUpdateToastOrderPostResult": [
      "CreateOrUpdateToastOrderPostSuccess",
      "UnexpectedError"
    ],
    "CreateSetupIntentResponse": [
      "CreateSetupIntentSuccessResponse",
      "SetupIntentError"
    ],
    "CreateSocialUserResult": [
      "CreateSocialUserSuccess",
      "UnexpectedError"
    ],
    "CustomerLoyaltyInfoResponseOrError": [
      "CustomerLoyaltyError",
      "LoyaltyInquiryResponse"
    ],
    "DeleteAccountResponseOrError": [
      "DeleteAccountError",
      "DeleteAccountResponse"
    ],
    "DeleteAddressResponseOrError": [
      "DeleteAddressError",
      "DeleteAddressResponse"
    ],
    "DeleteCreditCardResponseOrError": [
      "DeleteCreditCardError",
      "DeleteCreditCardResponse"
    ],
    "DeleteGuestAddressResult": [
      "DeleteGuestAddressSuccess",
      "UnexpectedError"
    ],
    "DeleteGuestPaymentCardResult": [
      "DeleteGuestPaymentCardSuccess",
      "UnexpectedError"
    ],
    "DoMenus_FindMenuItemResult": [
      "DoMenus_FindMenuItemError",
      "DoMenus_FindMenuItemResponse"
    ],
    "DoMenus_FindMenuItemsResult": [
      "DoMenus_FindMenuItemsError",
      "DoMenus_FindMenuItemsResponse"
    ],
    "DoMenus_GetMenuGroupsResult": [
      "DoMenus_GetMenuGroupsError",
      "DoMenus_GetMenuGroupsResponse"
    ],
    "DoMenus_GetMenuItemsResult": [
      "DoMenus_GetMenuItemsError",
      "DoMenus_GetMenuItemsResponse"
    ],
    "DoMenus_PopularItemsResult": [
      "DoMenus_PopularItemsError",
      "DoMenus_PopularItemsResponse"
    ],
    "Error": [
      "AlternativePaymentError",
      "ApplyCodeToGuestCurrencyAccountError",
      "ApplyPromoCodeError",
      "AuthorizePaymentError",
      "BirthdayAlreadyExistsError",
      "BookingBulkAvailabilitiesRestaurantLimitExceeded",
      "BookingDepositAmountChanged",
      "BookingDepositRuleNotMatching",
      "BookingGuestNotFound",
      "BookingInternalError",
      "BookingInvalidBooking",
      "BookingInvalidEmail",
      "BookingInvalidPartySize",
      "BookingInvalidPhoneNumber",
      "BookingNoTableAvailable",
      "BookingNotFound",
      "BookingOverlappingBooking",
      "BookingRequiresDepositData",
      "BookingRestaurantIsClosed",
      "BookingRestaurantNotFound",
      "BookingServiceAreaNotFound",
      "BookingUnableToCreateDepositOrder",
      "BookingUnableToCreateGuest",
      "BookingUnableToFetchGuests",
      "BookingUnableToRefundDeposit",
      "BookingUnableToSendSMS",
      "BookingUnableToUpdateReservation",
      "BookingUnauthorizedGuestProfile",
      "BookingUnsupportedTransition",
      "CON_ToastCashbackAlreadyIssuedReward",
      "CON_ToastCashbackInvalidInputError",
      "CON_ToastCashbackInvalidOrder",
      "CON_ToastCashbackProgramNotFoundError",
      "CON_ToastCashbackRewardFailed",
      "CartError",
      "CartModificationError",
      "CartOutOfStockError",
      "CartValidationError",
      "CodeAuthenticationError",
      "CodeExpiredError",
      "CodeMaxAttemptsReachedError",
      "CompleteGuestProfileInputRequiredError",
      "CompleteIdentityProfileError",
      "CompleteProfileCreationError",
      "CreateAccountError",
      "CreateMarketingSubscriptionRequestError",
      "CustomerLoyaltyError",
      "DeleteAccountError",
      "DeleteAddressError",
      "DeleteCreditCardError",
      "FingerprintCardError",
      "GeneralError",
      "GiftCardBalanceInquiryError",
      "GiftCardLookupError",
      "GiftCardNotFoundError",
      "GuestAddressNotFoundError",
      "GuestFeedbackV2Error",
      "GuestIdentityAlreadyExistsForAnotherGuestError",
      "GuestIdentityAlreadyExistsForCurrentGuestError",
      "GuestPaymentCardNotFoundError",
      "ImportAccountError",
      "IncorrectCodeError",
      "InvalidBirthdayError",
      "InvalidEmailError",
      "InvalidNameError",
      "InvalidPhoneFormatError",
      "InvalidRequestError",
      "InvalidSocialAcceptFollowRequestError",
      "LoginError",
      "LoyaltySignupError",
      "MakeCreditCardPrimaryError",
      "OPTOrderError",
      "OPTPartyError",
      "OPTTabError",
      "OnlineOrderingPaymentIntentError",
      "OrderAtTheTableGiftCardBalanceInquiryError",
      "PasswordlessAuthenticationError",
      "PayForCheckError",
      "PlaceNotFoundError",
      "PlaceOrderCartUpdatedError",
      "PlaceOrderError",
      "PlaceOrderOutOfStockError",
      "PopularItemsError",
      "ProfileLockedForDeletionError",
      "RankedPromoOffersError",
      "RefreshError",
      "ReorderError",
      "ResetPasswordError",
      "SetupIntentError",
      "SignUpToLoyaltyError",
      "SingleIdentityInputRequiredError",
      "SocialCannotFollowSelfError",
      "SocialOnlyMeUserActionProhibitedError",
      "SocialUserBannedError",
      "SocialUserDoesNotExistError",
      "StartIdentityProfileError",
      "StartProfileCreationError",
      "StoredValueCreateGiftError",
      "StoredValueEligiblePaymentMethodsError",
      "StoredValueRedeemGiftError",
      "StoredValueRedeemGiftRecoverableError",
      "StoredValueRedeemableGiftError",
      "StoredValueRedeemedGiftLookupError",
      "StoredValueSentGiftsError",
      "TooManyGuestReviewImagesError",
      "UnexpectedAuthError",
      "UnexpectedConfirmCodeError",
      "UnexpectedError",
      "UpdateAddressError",
      "UpdateBasicInfoError",
      "UpdatePasswordError",
      "ValidateApplePayMerchantError",
      "VerifyEmailError"
    ],
    "FingerprintAllCardsResponse": [
      "FingerprintAllCardsPartialSuccessResponse",
      "FingerprintAllCardsSuccessResponse",
      "UnexpectedError"
    ],
    "FutureFulfillmentTimeSlot": [
      "FutureFulfillmentServiceGap",
      "FutureFulfillmentTime"
    ],
    "GenerateGuestMediaPresignedUrlsResult": [
      "GenerateGuestMediaPresignedUrlsSuccess",
      "InvalidRequestError",
      "UnexpectedError"
    ],
    "GenerateGuestMediaUploadUrlsResult": [
      "GenerateGuestMediaUploadUrlsSuccess",
      "InvalidRequestError",
      "UnexpectedError"
    ],
    "GetMySocialUserResult": [
      "GetMySocialUserSuccess",
      "SocialUserDoesNotExistError",
      "UnexpectedError"
    ],
    "GetSocialUsersResult": [
      "GetSocialUsersSuccess",
      "SocialUserDoesNotExistError",
      "UnexpectedError"
    ],
    "GiftCardBalanceInquiryResponseOrError": [
      "GiftCardBalanceInquiryError",
      "GiftCardBalanceInquiryResponse"
    ],
    "GiftCardDataOrError": [
      "GiftCardData",
      "GiftCardLookupError",
      "GiftCardNotFoundError"
    ],
    "GuestCurrencyUnit": [
      "GuestCurrencyExpirableUnit",
      "GuestCurrencyStoredValueGiftUnit"
    ],
    "GuestNotificationHubItem": [
      "FollowRequestAcceptedGuestNotificationHubItem",
      "NewFollowRequestGuestNotificationHubItem",
      "NewFollowerGuestNotificationHubItem"
    ],
    "GuestOrderReviewPromptResult": [
      "GuestOrderReviewPrompt",
      "UnexpectedError"
    ],
    "GuestPostContent": [
      "ToastOrderGuestPostContent"
    ],
    "ImportAccountResponseOrError": [
      "ImportAccountError",
      "ImportAccountResponse"
    ],
    "InAppCheckIn_GetTableCheckResponse": [
      "InAppCheckIn_GetTableCheckError",
      "InAppCheckIn_GetTableCheckSuccess"
    ],
    "InAppCheckIn_GetTablesResponse": [
      "InAppCheckIn_GetTablesError",
      "InAppCheckIn_GetTablesSuccess"
    ],
    "InAppCheckIn_LinkCheckResponse": [
      "InAppCheckIn_LinkCheckError",
      "InAppCheckIn_LinkCheckSuccess"
    ],
    "InAppCheckIn_OpenChecksResponse": [
      "InAppCheckIn_OpenChecksError",
      "InAppCheckIn_OpenChecksSuccess"
    ],
    "Info": [
      "CartInfo"
    ],
    "InitiateCardLinkingByPaymentMethodIdResponse": [
      "InitiateCardLinkingByPaymentMethodIdSuccess",
      "UnexpectedError"
    ],
    "ItemDetailOfferBadgeResponseOrError": [
      "GeneralError",
      "ItemDetailOfferBadgeResponse"
    ],
    "ItemFeedbackConfigResponse": [
      "ItemFeedbackConfig",
      "ItemFeedbackConfigError"
    ],
    "ItemOrderHistoryResponseOrError": [
      "InvalidOffsetError",
      "ItemOrderHistoryResponse",
      "MissingCustomerOrGuestGuidError"
    ],
    "LinkCardFingerprintResponse": [
      "LinkCardFingerprintSuccess",
      "UnexpectedError"
    ],
    "LoginResponse": [
      "AuthenticationResponse",
      "LoginError",
      "MfaChallengeGeneratedResponse"
    ],
    "LoyaltyInquiryResponseOrError": [
      "CartOutOfStockError",
      "CustomerLoyaltyError",
      "LoyaltyInquiryResponse"
    ],
    "LoyaltySignupResponseOrError": [
      "LoyaltySignupError",
      "LoyaltySignupResponse"
    ],
    "MakeCreditCardPrimaryResponseOrError": [
      "MakeCreditCardPrimaryError",
      "MakeCreditCardPrimaryResponse"
    ],
    "MarketingConfigQueryResponse": [
      "MarketingConfigQueryError",
      "MarketingConfigQuerySuccessResponse"
    ],
    "MenuOfferBadgesResponseOrError": [
      "GeneralError",
      "MenuOfferBadgesResponse"
    ],
    "MenusResponseOrError": [
      "GeneralError",
      "MenusResponse"
    ],
    "OPTAddToTabResponseOrError": [
      "AddToOPTTabResponse",
      "OPTTabError"
    ],
    "OPTPartyCartResponse": [
      "OPTPartyError",
      "OrderAtTableCart"
    ],
    "OPTPartyRefreshResponse": [
      "OPTPartyError",
      "OPTPartyRefresh"
    ],
    "OPTPartyResponse": [
      "OPTParty",
      "OPTPartyError"
    ],
    "OPTPartyStubResponse": [
      "OPTPartyError",
      "OPTPartyStub"
    ],
    "OPTSplitPaymentChargesResponseOrError": [
      "OPTPartyError",
      "OPTSplitPaymentChargesResponse"
    ],
    "OPTTabHistoryResponse": [
      "OPTTabError",
      "OPTTabHistoryOrder"
    ],
    "OPT_CheckSelection": [
      "OPT_CheckSelectionGuid",
      "OPT_CheckSelectionNoGuid"
    ],
    "OPT_CreatePaymentIntentResponse": [
      "OPT_PaymentIntent",
      "OPT_PaymentIntentError"
    ],
    "OPT_Error": [
      "OPT_GeneralError",
      "OPT_GetMenusError",
      "OPT_GetPopularItemsError",
      "OPT_ItemFeedbackSurveyError",
      "OPT_OPTCartError",
      "OPT_OPTOrderError",
      "OPT_OPTPartiesLookupError",
      "OPT_OPTPartyError",
      "OPT_OPTPartyPaymentError",
      "OPT_OPTPartyPaymentErrorV2",
      "OPT_OPTSplitCheckPaymentError",
      "OPT_PayForCheckError",
      "OPT_PlaceOrderCartUpdatedError",
      "OPT_PlaceOrderError",
      "OPT_PlaceOrderOutOfStockError",
      "OPT_SignUpToLoyaltyError",
      "OPT_UpdateBasicInfoError",
      "OPT_ValidateApplePayMerchantError"
    ],
    "OPT_FeatureFlagResult": [
      "OPT_BooleanFeatureFlagResult",
      "OPT_NumericFeatureFlagResult",
      "OPT_StringFeatureFlagResult"
    ],
    "OPT_GetMenusResponseOrError": [
      "OPT_GetMenusError",
      "OPT_GetMenusResponse"
    ],
    "OPT_GetPopularItemsResponse": [
      "OPT_GetPopularItemsError",
      "OPT_PopularItemsV3"
    ],
    "OPT_Info": [],
    "OPT_ItemFeedbackSurveyResponse": [
      "OPT_ItemFeedbackSurvey",
      "OPT_ItemFeedbackSurveyError"
    ],
    "OPT_ItemLevelFeedbackSubmissionsStatus": [
      "OPT_ItemLevelFeedbackSubmissionFailure",
      "OPT_ItemLevelFeedbackSubmissionSuccess"
    ],
    "OPT_MDSCustomServerNotificationResponse": [
      "OPT_MDSServerNotificationResponse",
      "OPT_OPTPartyError"
    ],
    "OPT_OPTCartResponse": [
      "OPT_OPTCart",
      "OPT_OPTCartError"
    ],
    "OPT_OPTCheckV2": [
      "OPT_OPTCheckV2Guid",
      "OPT_OPTCheckV2NoGuid",
      "OPT_OPTSplitCheckPreview"
    ],
    "OPT_OPTCloseoutResponse": [
      "OPT_OPTPartyPaymentErrorV2",
      "OPT_OPTPartyPaymentResponse",
      "OPT_OPTSplitCheckPaymentError"
    ],
    "OPT_OPTOrder": [
      "OPT_OPTOrderGuid",
      "OPT_OPTOrderNoGuid"
    ],
    "OPT_OPTPartiesLookupResponse": [
      "OPT_OPTParties",
      "OPT_OPTPartiesLookupError"
    ],
    "OPT_OPTPartyCartResponse": [
      "OPT_OPTCart",
      "OPT_OPTPartyError"
    ],
    "OPT_OPTPartyRefreshResponseV2": [
      "OPT_OPTPartyError",
      "OPT_OPTPartyRefreshV2"
    ],
    "OPT_OPTPartyResponseV2": [
      "OPT_OPTPartyError",
      "OPT_OPTPartyV2"
    ],
    "OPT_OPTPartyStubResponse": [
      "OPT_OPTPartyError",
      "OPT_OPTPartyStub"
    ],
    "OPT_OPTSplitCheckResponse": [
      "OPT_OPTPartyError",
      "OPT_OPTSplitCheckPreview"
    ],
    "OPT_PartyMemberResponse": [
      "OPT_OPTPartyError",
      "OPT_OPTPartyMemberV2"
    ],
    "OPT_PayForCheckResponseOrError": [
      "OPT_PayForCheckError",
      "OPT_PayForCheckResponse"
    ],
    "OPT_PlaceApplePayOrderResponse": [
      "OPT_PlaceOrderCartUpdatedError",
      "OPT_PlaceOrderError",
      "OPT_PlaceOrderOutOfStockError",
      "OPT_PlaceOrderResponse"
    ],
    "OPT_PlaceOPTOrderResponse": [
      "OPT_OPTOrderError",
      "OPT_OPTPayForCheckResponse"
    ],
    "OPT_RestaurantOrError": [
      "OPT_GeneralError",
      "OPT_Restaurant"
    ],
    "OPT_SignUpToLoyaltyResponseOrError": [
      "OPT_SignUpToLoyaltyError",
      "OPT_SignUpToLoyaltyResponse"
    ],
    "OPT_UpdateBasicInfoResponseOrError": [
      "OPT_UpdateBasicInfoError",
      "OPT_UpdateBasicInfoResponse"
    ],
    "OPT_UpdatePaymentIntentResponse": [
      "OPT_PaymentIntentError",
      "OPT_UpdatePaymentIntentSuccess"
    ],
    "OPT_ValidateApplePayMerchantResponse": [
      "OPT_ValidateApplePayMerchantError",
      "OPT_ValidateApplePayMerchantSuccessResponse"
    ],
    "OPT_Warning": [
      "OPT_PlaceOrderWarning"
    ],
    "OfferBadge": [
      "MenuGroupOfferBadge",
      "MenuItemOfferBadge",
      "MenuOfferBadge"
    ],
    "OnlineOrderingSpiCreatePaymentIntentResponse": [
      "OnlineOrderingPaymentIntentError",
      "OnlineOrderingSpiCreatePaymentIntentSuccessResponse"
    ],
    "OnlineOrderingSpiGetClientTokenResponse": [
      "OnlineOrderingPaymentIntentError",
      "OnlineOrderingSpiGetClientTokenSuccessResponse"
    ],
    "OnlineOrderingSpiUpdatePaymentIntentResponse": [
      "OnlineOrderingPaymentIntentError",
      "OnlineOrderingSpiUpdatePaymentIntentSuccessResponse"
    ],
    "OrderAtTheTableGiftCardBalanceInquiryResponseOrError": [
      "OrderAtTheTableGiftCardBalanceInquiryError",
      "OrderAtTheTableGiftCardBalanceInquiryResponse"
    ],
    "Page": [
      "GuestCurrencyExpirableUnitPage",
      "GuestCurrencyUnitPage",
      "StoredValueGiftPage"
    ],
    "PageItem": [
      "GuestCurrencyExpirableUnit",
      "GuestCurrencyStoredValueGiftUnit",
      "StoredValueGiftDetails"
    ],
    "PasswordlessLoginResponseOrError": [
      "PasswordlessAuthenticationError",
      "PasswordlessLoginResponse"
    ],
    "PasswordlessLoginUnifiedResponseOrError": [
      "PasswordlessAuthenticationError",
      "PasswordlessLoginUnifiedResponse"
    ],
    "PasswordlessLogoutResponseOrError": [
      "PasswordlessAuthenticationError",
      "PasswordlessLogoutResponse"
    ],
    "PasswordlessSignupResponseOrError": [
      "PasswordlessAuthenticationError",
      "PasswordlessSignupResponse"
    ],
    "PasswordlessTokenResponseOrError": [
      "PasswordlessAuthenticationError",
      "PasswordlessTokenResponse"
    ],
    "PasswordlessTokenUnifiedResponseOrError": [
      "PasswordlessAuthenticationError",
      "PasswordlessTokenUnifiedResponse"
    ],
    "PayBearerTokenResponse": [
      "PayBearerToken",
      "PayBearerTokenError"
    ],
    "PayBreakdownLine": [
      "PayChargeLine",
      "PayDiscountLine",
      "PayPaymentLine",
      "PaySubtotalLine",
      "PayTaxLine",
      "PayTipLine",
      "PayTotalLine"
    ],
    "PayCheckoutByCheckResponse": [
      "PayCheckoutByCheckError",
      "PayCheckoutByCheckSuccess"
    ],
    "PayCheckoutByOrderResponse": [
      "PayCheckoutByOrderError",
      "PayCheckoutByOrderSuccess"
    ],
    "PayCloseoutByCheckResponse": [
      "PayCloseoutByCheckError",
      "PayCloseoutByCheckSuccess"
    ],
    "PayCreateMarketingSubscriptionResponse": [
      "PayMarketingSubscriptionError",
      "PayMarketingSubscriptionSuccess"
    ],
    "PayCreatePaymentIntentByCheckResponse": [
      "PayPaymentIntent",
      "PayPaymentIntentError"
    ],
    "PayForCheckResponseOrError": [
      "PayForCheckError",
      "PayForCheckResponse"
    ],
    "PayLine": [
      "PayDiscountLine",
      "PaySelectionLine",
      "PaySubtotalLine"
    ],
    "PayNamedAmount": [
      "PayChargeLine",
      "PayDiscountLine",
      "PayModifierLine",
      "PayPaymentLine",
      "PaySelectionLine",
      "PaySubtotalLine",
      "PayTaxLine",
      "PayTipLine",
      "PayTotalLine"
    ],
    "PaySelectionSubLine": [
      "PayDiscountLine",
      "PayModifierLine"
    ],
    "PaySplitCheckByItemResponse": [
      "PaySplitCheckByItemAvailabilityError",
      "PaySplitCheckByItemError",
      "PaySplitCheckByItemSuccess"
    ],
    "PayUpdatePaymentIntentByCheckResponse": [
      "PayUpdatePaymentIntentByCheckError",
      "PayUpdatePaymentIntentByCheckSuccess"
    ],
    "PaymentIntentResponseOrError": [
      "AlternativePaymentError",
      "PaymentIntentResponse"
    ],
    "PlaceApmOrderResponse": [
      "PlaceOrderCartUpdatedError",
      "PlaceOrderError",
      "PlaceOrderOutOfStockError",
      "PlaceOrderResponse"
    ],
    "PlaceApplePayOrderResponse": [
      "PlaceOrderCartUpdatedError",
      "PlaceOrderError",
      "PlaceOrderOutOfStockError",
      "PlaceOrderResponse"
    ],
    "PlaceCashOrderResponse": [
      "PlaceOrderCartUpdatedError",
      "PlaceOrderError",
      "PlaceOrderOutOfStockError",
      "PlaceOrderResponse"
    ],
    "PlaceCcOrderResponse": [
      "PlaceOrderCartUpdatedError",
      "PlaceOrderError",
      "PlaceOrderOutOfStockError",
      "PlaceOrderResponse"
    ],
    "PlaceGiftCardOrderResponse": [
      "PlaceOrderCartUpdatedError",
      "PlaceOrderError",
      "PlaceOrderOutOfStockError",
      "PlaceOrderResponse"
    ],
    "PlaceOPTOrderResponse": [
      "OPTOrderError",
      "OPTPayForCheckResponse"
    ],
    "PlacePaidOrderResponse": [
      "PlaceOrderCartUpdatedError",
      "PlaceOrderError",
      "PlaceOrderOutOfStockError",
      "PlaceOrderResponse"
    ],
    "PlaceResult": [
      "PlaceNotFoundError",
      "PlaceSuccess",
      "UnexpectedError"
    ],
    "PopularItemsResponseOrError": [
      "PopularItemsError",
      "PopularItemsResponse"
    ],
    "ProfileLockedError": [
      "ProfileLockedForDeletionError"
    ],
    "PromoBannerBase": [
      "DeliveryBanner",
      "LoyaltyBanner",
      "PromoCodeBanner"
    ],
    "PromoBannerResponseOrError": [
      "GeneralError",
      "PromoBannerResponse"
    ],
    "PromoOfferDiscountsResponseOrError": [
      "GeneralError",
      "PromoOfferDiscountsResponse"
    ],
    "RankedPromoOffersResponseOrError": [
      "RankedPromoOffersError",
      "RankedPromoOffersResponse"
    ],
    "RefreshResponse": [
      "AuthenticationResponse",
      "RefreshError"
    ],
    "RejectSocialFollowersResult": [
      "RejectSocialFollowersSuccess",
      "SocialUserDoesNotExistError",
      "UnexpectedError"
    ],
    "RemoveGuestReactionFromPostResponse": [
      "RemoveGuestReactionFromPostSuccess",
      "UnexpectedError"
    ],
    "RemoveGuestToastOrderImageReviewsResult": [
      "RemoveGuestToastOrderImageReviewsSuccess",
      "UnexpectedError"
    ],
    "RemovePromoCodeResponseOrError": [
      "CartModificationError",
      "CartOutOfStockError",
      "CartResponse"
    ],
    "RemoveSocialFollowersResult": [
      "RemoveSocialFollowersSuccess",
      "SocialUserDoesNotExistError",
      "UnexpectedError"
    ],
    "ReorderResponseOrError": [
      "ReorderError",
      "ReorderResponse"
    ],
    "ReportGuestPostResult": [
      "ReportGuestPostSuccess",
      "UnexpectedError"
    ],
    "ReportSocialUserResult": [
      "ReportSocialUserSuccess",
      "SocialUserDoesNotExistError",
      "UnexpectedError"
    ],
    "ResetPasswordResponse": [
      "AuthenticationResponse",
      "ResetPasswordError"
    ],
    "RestaurantOfferBadgeResponseOrError": [
      "GeneralError",
      "RestaurantOfferBadgeResponse"
    ],
    "RestaurantOfferBadgeV2ResponseOrError": [
      "GeneralError",
      "RestaurantOfferBadgeV2Response"
    ],
    "RestaurantOrError": [
      "GeneralError",
      "Restaurant"
    ],
    "RestaurantSearchResultsOrError": [
      "GeneralError",
      "RestaurantSearchResults"
    ],
    "SetupGuestResult": [
      "InvalidPhoneFormatError",
      "ProfileLockedForDeletionError",
      "SetupGuestSuccess",
      "SingleIdentityInputRequiredError",
      "UnexpectedAuthError"
    ],
    "SetupIntentBearerTokenResponse": [
      "SetupIntentBearerToken",
      "SetupIntentBearerTokenError"
    ],
    "SignUpToLoyaltyResponseOrError": [
      "SignUpToLoyaltyError",
      "SignUpToLoyaltyResponse"
    ],
    "SocialFollowResult": [
      "SocialCannotFollowSelfError",
      "SocialFollowSuccess",
      "SocialOnlyMeUserActionProhibitedError",
      "SocialUserDoesNotExistError",
      "UnexpectedError"
    ],
    "SocialUnfollowResult": [
      "SocialUnfollowSuccess",
      "SocialUserDoesNotExistError",
      "UnexpectedError"
    ],
    "SocialUserReportReasonsResult": [
      "SocialUserReportReasonsSuccess",
      "UnexpectedError"
    ],
    "SocialUserSearchResult": [
      "SocialUserSearchSuccess",
      "UnexpectedError"
    ],
    "StartProfileCreationResponseOrError": [
      "StartProfileCreationError",
      "StartProfileCreationResponse"
    ],
    "StoredValueCreateGiftResponse": [
      "StoredValueCreateGiftError",
      "StoredValueCreateGiftSuccess"
    ],
    "StoredValueEligiblePaymentMethodsResult": [
      "StoredValueEligiblePaymentMethodsError",
      "StoredValueEligiblePaymentMethodsSuccess"
    ],
    "StoredValueRedeemGiftResponse": [
      "StoredValueRedeemGiftError",
      "StoredValueRedeemGiftRecoverableError",
      "StoredValueRedeemGiftSuccess"
    ],
    "StoredValueRedeemableGiftResponse": [
      "StoredValueRedeemableGiftError",
      "StoredValueRedeemableGiftSuccess"
    ],
    "StoredValueRedeemedGiftLookupResponse": [
      "StoredValueRedeemedGiftLookupError",
      "StoredValueRedeemedGiftLookupSuccess"
    ],
    "StoredValueSentGiftsResult": [
      "StoredValueGiftPage",
      "StoredValueSentGiftsError"
    ],
    "SubmitGuestFeedbackV2Response": [
      "GuestFeedbackV2Error",
      "GuestFeedbackV2SuccessResponse"
    ],
    "SubmitGuestRatingResult": [
      "GuestRating",
      "SubmitGuestRatingSuccess",
      "UnexpectedError"
    ],
    "SubmitGuestToastOrderImageReviewsResult": [
      "SubmitGuestToastOrderImageReviewsSuccess",
      "TooManyGuestReviewImagesError",
      "UnexpectedError"
    ],
    "SubmitGuestToastOrderTextReviewResult": [
      "SubmitGuestToastOrderTextReviewSuccess",
      "UnexpectedError"
    ],
    "ToastOrderGuestPostContentHighlight": [
      "ToastOrderGuestPostContentImageReview",
      "ToastOrderGuestPostContentUniqueMenuItem"
    ],
    "ToastOrderGuestPostResult": [
      "ToastOrderGuestPostSuccess",
      "UnexpectedError"
    ],
    "UnbanSocialUserResult": [
      "SocialUserDoesNotExistError",
      "UnbanSocialUserSuccess",
      "UnexpectedError"
    ],
    "UnlinkCardFingerprintResponse": [
      "UnexpectedError",
      "UnlinkCardFingerprintSuccess"
    ],
    "UpdateAddressResponseOrError": [
      "UpdateAddressError",
      "UpdateAddressResponse"
    ],
    "UpdateBasicInfoResponseOrError": [
      "UpdateBasicInfoError",
      "UpdateBasicInfoResponse"
    ],
    "UpdateGuestAddressResult": [
      "GuestAddressNotFoundError",
      "UnexpectedError",
      "UpdateGuestAddressSuccess"
    ],
    "UpdateGuestContactResult": [
      "InvalidNameError",
      "UnexpectedError",
      "UpdateGuestContactSuccess"
    ],
    "UpdateGuestPostVisibilityResult": [
      "UnexpectedError",
      "UpdateGuestPostVisibilitySuccess"
    ],
    "UpdateMySocialUserResult": [
      "SocialUserBannedError",
      "SocialUserDoesNotExistError",
      "UnexpectedError",
      "UpdateMySocialUserSuccess"
    ],
    "UpdatePasswordResponseOrError": [
      "UpdatePasswordError",
      "UpdatePasswordResponse"
    ],
    "UpdatePrimaryGuestPaymentCardResult": [
      "GuestPaymentCardNotFoundError",
      "UnexpectedError",
      "UpdatePrimaryGuestPaymentCardSuccess"
    ],
    "UrlShortenerResolvedResponseOrError": [
      "UrlShortenerResolvedResponse",
      "UrlShortenerResponseError"
    ],
    "UrlShorteningResponseOrError": [
      "UrlShortenerResponseError",
      "UrlShorteningResponse"
    ],
    "ValidateApplePayMerchantResponse": [
      "ValidateApplePayMerchantError",
      "ValidateApplePayMerchantSuccessResponse"
    ],
    "ValidationError": [
      "InvalidBirthdayError",
      "InvalidEmailError",
      "InvalidNameError",
      "InvalidPhoneFormatError",
      "SingleIdentityInputRequiredError"
    ],
    "VerifyEmailResponse": [
      "AuthenticationResponse",
      "VerifyEmailError"
    ],
    "Walkout_CreatePreauthOrderForBookingResponse": [
      "Walkout_CreatePreauthOrderForBookingError",
      "Walkout_CreatePreauthOrderForBookingSuccess"
    ],
    "Walkout_WalkoutResponse": [
      "Walkout_WalkoutError",
      "Walkout_WalkoutSuccess"
    ],
    "Warning": [
      "CartWarning",
      "DeliveryUnavailableReason",
      "PlaceOrderWarning",
      "ReorderWarning"
    ]
  }
};
      export default result;
    
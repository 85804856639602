import React from 'react';

import { OrderPageContent } from 'public/components/default_template/OrderPage';
import { rxLocationSearchUrl } from 'public/components/toast_local/common/searchUtils';

import TitleCard from './TitleCard';
import Breadcrumbs from './breadcrumbs/Breadcrumbs';
import CuisinePills from './cuisine_pills/CuisinePills';
import Footer from './footer/Footer';
import { formatRXName } from './formatting';
import HeaderImage from './header_image/HeaderImage';
import Hours from './hours/Hours';
import Location from './location/Location';
import Overview from './overview/Overview';
import { RestaurantProfileRestaurantType, NearbyRestaurantSearchResultRestaurantType } from './profileUtils';
import RXCarousel from './rx_carousel/RXCarousel';


type Props = {
  restaurant: RestaurantProfileRestaurantType;
  nearbyRxs?: NearbyRestaurantSearchResultRestaurantType[];
  scrollNavRef: React.Ref<HTMLDivElement>;
  navOpen?: boolean;
  usingSecondaryNav?: boolean;
};

const RestaurantProfile = ({ restaurant, nearbyRxs, scrollNavRef, navOpen, usingSecondaryNav }: Props) => {
  return (
    <>
      <div className="paddedSection restaurantProfile">
        <Breadcrumbs restaurant={restaurant} />
        <div className="lg-reverse">
          <HeaderImage
            id="rx_profile"
            rxName={formatRXName(restaurant.name || '')}
            location={restaurant.location}
            bannerImageUrl={restaurant.bannerUrls?.medium} />
          <div className="rx-info">
            <div>
              <CuisinePills restaurant={restaurant} />
              <div className="title-info">
                <h1 className="rx-page-section">{formatRXName(restaurant.name || '')}</h1>
              </div>
            </div>
            <div className="overview-container">
              <Overview
                hoursHref="#rx-hours"
                phone={restaurant.location?.phone}
                location={restaurant.location}
                giftCardHref={restaurant.giftCardLinks?.purchaseLink} />
            </div>
          </div>
        </div>
      </div>

      <OrderPageContent
        scrollNavRef={scrollNavRef}
        navOpen={navOpen}
        hideHero={true}
        usingSecondaryNav={usingSecondaryNav} />

      <div className="paddedSection rxPageSectionWrapper">
        <div><hr className="divider" /></div>
        <div className="rx-page-section rx-cards">
          <div className="rx-hours" id="hours">
            <TitleCard title="All hours" defaultState="open">
              <Hours schedule={restaurant.schedule} />
            </TitleCard>
          </div>
          {(restaurant.description || restaurant.socialMediaLinks?.restaurantLink) &&
            <div className="rx-description">
              <TitleCard title="Restaurant info" defaultState="open">
                {restaurant.description && <p>{restaurant.description}</p>}
                {restaurant.socialMediaLinks?.restaurantLink && <p><a href={restaurant.socialMediaLinks?.restaurantLink} target="_blank" rel="noreferrer noopener">Website</a></p>}
              </TitleCard>
            </div>}
          {restaurant.location &&
            <div className="location">
              <TitleCard title="Location" defaultState="open">
                <Location location={restaurant.location} restaurantName={formatRXName(restaurant.name || '')} />
              </TitleCard>
            </div>}
        </div>
      </div>
      {nearbyRxs?.length ?
        <div className="paddedSection rxPageSectionWrapper">
          <div><hr className="divider" /></div>
          <RXCarousel
            title="Similar restaurants in your area"
            elements={nearbyRxs}
            className="rx-page-section"
            elementClass="pure-u-12-24 pure-u-md-8-24 pure-u-lg-6-24"
            moreRxHref={rxLocationSearchUrl(restaurant.location)}
            moreRxDescription="Search similar restaurants" />
        </div>
        : null}
      <Footer />
    </>
  );
};


export default RestaurantProfile;

import React, { useCallback, useRef, useMemo } from 'react';

import classnames from 'classnames';

import { ThemeTypeEnum } from 'src/apollo/sites';
import { CarouselAriaProps } from 'src/shared/components/common/carousel/types';

import CarouselArrows from 'shared/components/common/carousel/CarouselArrows';
import { ScreenWidth, useIsMobile } from 'shared/js/utils/WindowContext';

import CarouselElement from './CarouselElement';
import { MOBILE_MIN_CAROUSEL_ITEMS, useCarousel } from './useCarousel';

export enum CarouselTypes {
  Reviews,
  Cards,
  Gallery
}

type Props = {
  id: string;
  hideArrows?: boolean | null;
  wrapItems?: boolean | null;
  items: React.ReactElement<HTMLLIElement>[];
  primaryColor?: string;
  whiteInactiveArrow?: boolean;
  arrowSize?: 'small' | 'large';
  theme?: ThemeTypeEnum | null;
  carouselRef: React.RefObject<HTMLDivElement>;
  carouselType?: CarouselTypes
  containerClassNames?: string
} & CarouselAriaProps;

const Carousel = ({ id, hideArrows, wrapItems, items, primaryColor, whiteInactiveArrow, arrowSize, theme, carouselRef, containerClassNames, ariaLabel }: Props) => {
  const containerRef = useRef<HTMLUListElement>(null);
  const isMobile = useIsMobile(ScreenWidth.EXTRA_SMALL);
  const { showOneItem, itemNamesMap, itemsPerPage, scroll, currentItem, totalScrollItems } = useCarousel({
    containerId: id,
    containerRef,
    theme,
    numItems: items.length,
    itemsPerPage: isMobile ? 1 : undefined
  });

  /** isAriaVisible determines if an item is within the expected visible range.
   * We use this information to hide non-visible elements from screen readers
   * for a better user experience to users of screen readers. */
  const isAriaVisible = useCallback((itemIndex: number) => {
    if(wrapItems) {
      // when wrapping items in a carousel, all items are visible by default.
      return true;
    }
    const start = currentItem;
    const end = Math.min(start + itemsPerPage);
    return itemIndex >= start && itemIndex < end;
  }, [itemsPerPage, currentItem, wrapItems]);

  const mobileScrollCards = !showOneItem && !wrapItems && items.length > MOBILE_MIN_CAROUSEL_ITEMS;
  const showArrows = useMemo(() => !(hideArrows || isMobile && !mobileScrollCards && !showOneItem) && items.length > itemsPerPage,
    [hideArrows, isMobile, items.length, itemsPerPage, mobileScrollCards, showOneItem]);

  return (
    <div
      className={classnames('carouselWrapper', { showOneItem, mobileScrollCards }, containerClassNames)}
      role={showArrows ? 'group' : undefined}
      aria-roledescription={showArrows ? 'carousel' : undefined}
      aria-label={showArrows ? ariaLabel : undefined}>
      <div className="carousel">
        <div className={classnames({ paddedSection: theme !== ThemeTypeEnum.Wide })}>
          <ul className={classnames('itemsWrapper themeRow', theme)} id={id} data-testid={`${id}-itemsWrapper`} ref={containerRef} >
            {items.map((item, i) =>
              <CarouselElement
                key={`scrollElement-${id}-${i}`}
                name={itemNamesMap.get(i)!}
                role="listitem"
                aria-hidden={!isAriaVisible(i)}
                aria-roledescription="slide"
                data-testid={itemNamesMap.get(i)}>
                {item}
              </CarouselElement>)}
          </ul>
        </div>
      </div>
      {showArrows &&
        <div className="paddedSection">
          <div>
            <CarouselArrows
              whiteInactiveArrow={whiteInactiveArrow}
              color={primaryColor || '#000000'}
              arrowSize={arrowSize}
              data-testid={`carousel-arrows-${id}`}
              arrowsRef={carouselRef}
              onClickScroll={scroll}
              currentItem={currentItem}
              totalScrollItems={totalScrollItems} />
          </div>
        </div>}
    </div>
  );
};

export default Carousel;

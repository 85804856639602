import React, { useMemo, useRef } from 'react';
import { withRouter } from 'react-router';

import { useEditor } from '@toasttab/sites-components';

import { BulkCalls, MenuPage, PageConfig, SiteContentDataFragment } from 'src/apollo/sites';
import { RequestContextProps } from 'src/lib/js/context';
import { BulkCallsContextProvider } from 'src/shared/components/common/dynamic_menus/BulkCallsContext';

import { useRestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';
import NoMatch404 from 'shared/components/no_match_404/NoMatch404';

import { CTAData } from 'public/components/default_template/ctas';
import Footer from 'public/components/default_template/footer';
import { EditPathRoot, MainPageSections } from 'public/components/default_template/main_page/MainPage';
import Nav from 'public/components/default_template/nav/Nav';


type SiteContent = NonNullable<SiteContentDataFragment>['content'] & { __typename: 'SiteContent' };

type DynamicMenuPageProps = {
  editableRef?: React.RefObject<HTMLElement>,
  content?: MenuPage;
  editPathRoot?: EditPathRoot
  config?: PageConfig | null;
  classNames?: string;
  bulkCalls?: BulkCalls | null
}

export const DynamicMenuPage = (props: DynamicMenuPageProps) => {
  const { restaurant } = useRestaurant();

  const backgroundColorStyle = useMemo(() => {
    return props.config?.backgroundColor
      ? { backgroundColor: props.config?.backgroundColor }
      : {};
  }, [props.config?.backgroundColor]);

  const { meta, theme } = restaurant;
  let { primaryCta = null, nonPrimaryCtas = null } = restaurant.content as SiteContent;

  if(!restaurant.content || !props.editPathRoot) {
    return <NoMatch404 meta={meta} siteTheme={theme} />;
  }

  const bodyPrimaryCta = !primaryCta?.nav ? primaryCta as CTAData : null;
  const bodyNonPrimaryCtas = nonPrimaryCtas?.filter((cta: CTAData) => !cta.nav);

  return (
    <div className="defaultTemplate menuPage" data-testid="menuPage" style={backgroundColorStyle} id="main" ref={props.editableRef as any}>
      <BulkCallsContextProvider bulkMenuItemIds={props.bulkCalls?.menuItems || restaurant.content.bulkCalls?.menuItems }>
        <MainPageSections
          sections={props.content?.sections || []}
          contentTheme={restaurant.content.theme}
          bodyPrimaryCta={bodyPrimaryCta}
          bodyNonPrimaryCtas={bodyNonPrimaryCtas}
          meta={meta}
          editPathRoot={props.editPathRoot} />
      </BulkCallsContextProvider>
    </div>
  );
};

export const WrappedDynamicMenuPage = React.memo(
  function WrappedDynamicMenuPage({ content, editPathRoot, config }: Partial<RequestContextProps> & DynamicMenuPageProps) {
    const { restaurant } = useRestaurant();
    const { restaurant: siteRestaurant } = useRestaurant();
    const { useEditableRef } = useEditor();

    const backgroundColorStyle = useMemo(() => {
      return config?.backgroundColor
        ? { backgroundColor: config?.backgroundColor }
        : {};
    }, [config?.backgroundColor]);

    const { meta, theme } = restaurant;

    const ignoreClickRef = useRef(null);
    const { editableRef: editableDynamicMenuRef } = useEditableRef<HTMLDivElement>({
      name: 'dynamic menu page',
      actions: [],
      skipRef: ignoreClickRef
    });

    if(!content || !editPathRoot) {
      return <NoMatch404 meta={meta} siteTheme={theme} />;
    }

    let primaryCta = siteRestaurant?.content?.primaryCta || null;
    let nonPrimaryCtas = siteRestaurant?.content?.nonPrimaryCtas || null;

    return (
      <div className="defaultTemplate" data-testid="dynamic-menu-page" style={backgroundColorStyle} id="main">
        <Nav
          logoSrc={meta.icon}
          logoObject={meta.iconObject}
          navType="normal"
          primaryCta={primaryCta}
          nonPrimaryCtas={nonPrimaryCtas}
          shouldShowPreviewBanner={true} />
        <DynamicMenuPage content={content} editPathRoot={editPathRoot} config={config} editableRef={editableDynamicMenuRef} />
        <Footer />
      </div>
    );
  }
);

export default withRouter<RequestContextProps & DynamicMenuPageProps, React.ComponentType<RequestContextProps & DynamicMenuPageProps>>(WrappedDynamicMenuPage);

import React, { useCallback, useEffect } from 'react';

import { DiningOptionBehavior } from 'src/apollo/onlineOrdering';
import useTracker from 'src/lib/js/hooks/useTracker';

import { Modal, ModalContent, ModalOverlay, ModalCloseButton, useModal } from 'shared/components/common/modal';
import { useRestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';
import { toFutureString } from 'shared/js/timeUtils';

import { useCart } from 'public/components/online_ordering/CartContext';
import { useCheckout } from 'public/components/online_ordering/CheckoutContext';
import { OrderError } from 'public/components/online_ordering/CheckoutContext';
import { getQuoteTime } from 'public/components/online_ordering/fulfillmentUtils';

const CheckoutError = ({ error } : { error?: OrderError | null }) => {
  const { cart } = useCart();
  const { ooRestaurant } = useRestaurant();
  const tracker = useTracker();

  if(!error || !cart || !ooRestaurant) {
    return null;
  }

  const newOrderTime =
    cart.fulfillmentType === 'ASAP' ?
      `${getQuoteTime(cart.diningOptionBehavior, ooRestaurant, cart)} minutes` :
      toFutureString(cart.fulfillmentDateTime || '');

  switch(error.type) {
    case 'ORDER_TIME_CHANGED':
    case 'order-time':
    case 'place-order:order-time-changed':
      return <>Your order time is no longer available. The new time for your order is {newOrderTime}.</>;
    case 'OUT_OF_STOCK':
    case 'oos-item-removed':
      return <>Sorry, one of the items in your order is out of stock. It has been removed from your order.</>;
    case 'ORDER_TIME_UNAVAILABLE':
      return <>Sorry, your order can no longer be completed at this time. Please pick a new order time.</>;
    case 'UNSUPPORTED_PROMO_CODE':
      return (
        <>
          <div>You have already redeemed this one-time promotion. Please remove the promo code and try again.</div>
          <div>You may see a temporary hold on your card, but it will be released in 24-48 hours and your card account will not be charged.</div>
        </>
      );
    case 'INVALID_CREDIT_CARD':
      return <>Your credit card was declined. You have not been charged, but may notice a temporary authorization on your statement. Please review card information and try again.</>;
    case 'OO_DISABLED':
    case 'online-ordering-disabled':
      return <>Sorry, we are not accepting online orders at this time.</>;
    case 'PLACE_ORDER_EMPTY_CART':
    case 'CART_CONTENT_NOT_FOUND':
    case 'empty-cart-place-order':
    case 'cart-content-not-found':
      return <>Please add some items to your order.</>;
    case 'PLACE_ORDER_INVALID_EMAIL':
      return <>Please enter a valid email address.</>;
    case 'UNSUPPORTED_PAYMENT_METHOD':
      return <>Sorry, we do not accept this type of credit card. Please try a different one.</>;
    case 'MISSING_CUSTOMER_INFO':
      return <>Please enter your name, email address, and phone number.</>;
    case 'MISSING_DELIVERY_INFO':
      return <>Please enter an address for delivery.</>;
    case 'DINING_OPTION_UNAVAILABLE':
      return <>Sorry, {cart.diningOptionBehavior === DiningOptionBehavior.Delivery ? 'delivery' : 'take out'} is not available right now.</>;
    case 'NOT_IN_DELIVERY_AREA':
      return <>Sorry, we do not deliver to your address.</>;
    case 'ALCOHOL_UNSUPPORTED':
    case 'place-order:alcohol-unsupported':
      return <>Sorry, we cannot complete this order with alcoholic items. Please remove them and try again.</>;
    case 'AUTOFIRE_OFFLINE':
      return (
        <>
          We are experiencing issues processing online orders. This issue is typically resolved in less than 5 minutes. If the error persists, contact the restaurant directly to order.
        </>
      );
    case 'CRITICAL_ERROR':
    case 'PLACE_ORDER_FAILED':
    case 'critical-error':
    case 'cart-operation-failed':
    case 'cart-operation-invalid':
      return (
        <>
          We had trouble placing your order. Please try again, or contact the restaurant directly to place an order.
        </>
      );
    case 'CUSTOM_ERROR_MESSAGE':
      return <>{error.message}</>;
    default: {
      // Some error messages come through as non-customer-facing, stringified error objects formatted as "[<ErrorType>(reasonCode=<string>, reasonMsg=<string>)]"
      // In that case, pull out the contents of the reasonMsg field.  Otherwise, display the original message.
      const reasonMsg = error.message.split('reasonMsg=')[1]?.slice(0, -2) ?? error.message;
      tracker.track('Unknown checkout error type', {
        cartSource: cart.cartSource,
        type: error.type,
        message: error.message
      });
      return <>{reasonMsg}</>;
    }
  }
};

const CheckoutErrorModal = () => {
  const { isOpen, onClose, onOpen } = useModal();
  const { orderError, setOrderError } = useCheckout();
  const { refetchCart } = useCart();

  useEffect(() => {
    if(orderError) {
      onOpen();
    }
  }, [orderError, onOpen]);

  const closeModal = useCallback(() => {
    setOrderError(null);
    onClose();
    if(orderError?.refetchCart) {
      refetchCart();
    }
  }, [orderError, refetchCart, setOrderError, onClose]);

  return (
    <>
      <Modal onClose={closeModal} isOpen={isOpen} preventOverlayClose>
        <ModalOverlay />
        <ModalContent>
          <div className="checkoutErrorModalContent">
            <div className="header">
              <h3>There was an error placing your order!</h3>
              <ModalCloseButton />
            </div>
            <div className="body">
              {orderError && <div className="submitError"><CheckoutError error={orderError} /></div>}
            </div>
          </div>
        </ModalContent>
      </Modal>
    </>
  );
};

export default CheckoutErrorModal;

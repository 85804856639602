import React, { createContext, useCallback, useContext, useEffect, useMemo, useRef, useReducer } from 'react';

import * as Sentry from '@sentry/react';
import { checkLocale } from '@toasttab/buffet-pui-locale-utilities';
import { v4 as uuid } from 'uuid';

import {
  BalanceStateData,
  LoyaltyFeatures,
  LoyaltyInquiryResponse,
  LoyaltyRedemption,
  Order,
  useAddLoyaltyToCartMutation,
  useCustomerLoyaltyInfoQuery,
  useCustomerLoyaltySignupMutation,
  useLoyaltyFeaturesQuery,
  useLoyaltyInquiryMutation,
  useRemoveLoyaltyFromCartMutation
} from 'src/apollo/onlineOrdering';
import { getFirstOrThirdParty, getLoyaltyOptions, getRedemptionGuid, LoyaltyProvider } from 'src/public/components/default_template/online_ordering/checkout/loyalty/LoyaltyUtils';
import { useRestaurant } from 'src/shared/components/common/restaurant_context/RestaurantContext';

import { useCart } from './CartContext';
import { useCustomer } from './CustomerContextCommon';

export interface LoyaltyContextType {
  accountIdentifier?: string | null;
  hasLoyaltyAccount: boolean;
  pointsBalance: number;
  currentState: BalanceStateData | null;
  redemptions: LoyaltyRedemption[];
  removeRedemption: (redemption: LoyaltyRedemption) => Promise<boolean>;
  addRedemption: (redemption: LoyaltyRedemption) => Promise<boolean>;
  loadingLoyaltyAccount: boolean;
  loadingLoyaltyInquiry: boolean;
  loadingRedemptions: boolean;
  redemptionErrorRef: React.RefObject<string>;
  // used to restrict sending user info to 3rd parties without the guest's consent
  setAllowLookup: (value: boolean, store: boolean) => void;
  allowLookup: boolean;
  loyaltyLoading: boolean;
  programDescription?: string | null;
  loyaltyFeatures?: (LoyaltyFeatures | null)[] | null;
  loadingLoyaltyFeatures: boolean;
  signupForLoyalty: (customerGuid: string, checkIdentifier?: string) => Promise<any>;
  loadingSignup: boolean;
}

export const LoyaltyContext = createContext<LoyaltyContextType | undefined>(undefined);

type LoyaltyAccountLoadType = {
  hasLoyaltyAccount: boolean;
  pointsBalance: number;
  redemptions: LoyaltyRedemption[];
  loyaltyLoading: boolean;
  currentState: BalanceStateData | null;
};

const LOYALTY_ACCOUNT_ACTION_TYPES = {
  ACCOUNT_LOADED: 'ACCOUNT_LOADED',
  ACCOUNT_LOAD_ERROR: 'ACCOUNT_LOAD_ERROR',
  NOT_LOGGED_IN: 'NOT_LOGGED_IN'
};

const LOYALTY_ACCOUNT_INIT: LoyaltyAccountLoadType = {
  hasLoyaltyAccount: false,
  pointsBalance: 0,
  redemptions: [],
  loyaltyLoading: true,
  currentState: null
};

const loyaltyAccountReducer = (state: LoyaltyAccountLoadType, action: { type: string, payload?: Omit<LoyaltyAccountLoadType, 'loyaltyLoading'> }): LoyaltyAccountLoadType => {
  switch(action.type) {
    case LOYALTY_ACCOUNT_ACTION_TYPES.ACCOUNT_LOADED:
      return {
        ...state,
        ...action.payload,
        loyaltyLoading: false
      };
    case LOYALTY_ACCOUNT_ACTION_TYPES.ACCOUNT_LOAD_ERROR:
    case LOYALTY_ACCOUNT_ACTION_TYPES.NOT_LOGGED_IN:
      return {
        ...state,
        loyaltyLoading: false
      };
    default:
      return state;
  }
};

type LoyaltyConsentLoadType = {
  allowLookup: boolean;
  lookupLoading: boolean;
};

const LOYALTY_CONSENT_ACTION_TYPES = { ALLOW: 'ALLOW' };

const LOYALTY_CONSENT_INIT: LoyaltyConsentLoadType = {
  allowLookup: false,
  lookupLoading: true
};

const loyaltyConsentReducer = (state: LoyaltyConsentLoadType, action: string ): LoyaltyConsentLoadType => {
  switch(action) {
    case LOYALTY_CONSENT_ACTION_TYPES.ALLOW:
      return {
        allowLookup: true,
        lookupLoading: false
      };
    default:
      return state;
  }
};

export const LoyaltyContextProvider = (props : React.PropsWithChildren<{}>) => {
  const [loyaltyAccount, dispatchLoyaltyAccount] = useReducer(loyaltyAccountReducer, LOYALTY_ACCOUNT_INIT);
  const [loyaltyConsent, dispatchLoyaltyConsent] = useReducer(loyaltyConsentReducer, LOYALTY_CONSENT_INIT);
  const redemptionErrorRef = useRef('');
  const [loyaltyInquiry, { loading: loadingLoyaltyInquiry }] = useLoyaltyInquiryMutation();

  const [addLoyalty, { loading: loadingAdd }] = useAddLoyaltyToCartMutation();
  const [removeLoyalty, { loading: loadingRemove }] = useRemoveLoyaltyFromCartMutation();
  const [customerLoyaltySignupMutation, { loading: loadingSignup }] = useCustomerLoyaltySignupMutation();
  const { cart, cartGuid } = useCart();
  const { customer, loadingCustomer } = useCustomer();
  const { ooRestaurant } = useRestaurant();
  const { data: loyaltyFeaturesData, loading: loadingLoyaltyFeatures } = useLoyaltyFeaturesQuery( {
    variables: {
      input: {
        restaurantGuid: ooRestaurant?.guid ?? '',
        locale: ooRestaurant?.i18n.locale
      }
    },
    skip: !ooRestaurant?.loyaltyConfig?.programName || !checkLocale(ooRestaurant?.i18n.locale),
    ssr: false
  });
  const { data: customerLoyaltyInfoData, loading: loadingLoyaltyAccount, refetch: refetchCustomerLoyaltyInfo } = useCustomerLoyaltyInfoQuery({
    variables: { input: { restaurantGuid: ooRestaurant?.guid ?? '' } },
    skip: !customer?.id || !ooRestaurant?.guid || !ooRestaurant?.loyaltyConfig?.programName
  });

  const getAllowLookupConsentSessionKey = useCallback(() => `${customer?.id}#${cartGuid}`, [cartGuid, customer?.id]);

  const saveAllowLookupConsentSession = useCallback((value: boolean) => {
    // only allow one active session so we don't have to manage stale sessions
    localStorage.setItem('oo.toast.consent.session', JSON.stringify({ [getAllowLookupConsentSessionKey()]: value }));
  }, [getAllowLookupConsentSessionKey]);

  const getAllowLookupConsentSession = useCallback(() => {
    try {
      const session = JSON.parse(localStorage.getItem('oo.toast.consent.session') || '{}');
      return Boolean(session[getAllowLookupConsentSessionKey()]);
    } catch{
      return false;
    }
  }, [getAllowLookupConsentSessionKey]);

  const setAllowLookup = useCallback((value: boolean, store: boolean) => {
    if(store) {
      saveAllowLookupConsentSession(value);
    }
    dispatchLoyaltyConsent(LOYALTY_CONSENT_ACTION_TYPES.ALLOW);
  }, [saveAllowLookupConsentSession]);

  useEffect(() => {
    if(cartGuid !== null && customer?.id !== null && getAllowLookupConsentSession() && !loyaltyConsent.allowLookup) {
      // user has already consented in this session
      dispatchLoyaltyConsent(LOYALTY_CONSENT_ACTION_TYPES.ALLOW);
    }
  }, [cartGuid, customer?.id, getAllowLookupConsentSession, loyaltyConsent]);

  const loadingRedemptions = useMemo(() => loadingAdd || loadingRemove, [loadingAdd, loadingRemove]);

  const setError = useCallback(async () => {
    redemptionErrorRef.current = 'There was an error while updating your cart. Please double check your order before placing it.';
  }, []);

  const removeRedemption = useCallback( async (redemption: LoyaltyRedemption) => {
    if(!loadingRedemptions && cartGuid) {
      try {
        const response = await removeLoyalty({
          variables: {
            input:
              {
                cartGuid,
                redemption: { type: redemption.type, referenceId: redemption.referenceId, guid: getRedemptionGuid(redemption) }
              }
          }
        });
        if(response?.data?.removeLoyaltyRedemptionV2.__typename !== 'CartResponse') {
          setError();
        } else {
          redemptionErrorRef.current = '';
          return true;
        }
      } catch(_) {
        setError();
      }
    }

    return false;
  }, [cartGuid, loadingRedemptions, removeLoyalty, setError]);

  const addRedemption = useCallback( async (redemption: LoyaltyRedemption) => {
    if(!loadingRedemptions && cartGuid) {
      try {
        const response = await addLoyalty({ variables: { input: { cartGuid, redemption: { type: redemption.type, referenceId: redemption.referenceId, guid: redemption.redemptionGuid } } } });
        if(response?.data?.addLoyaltyRedemptionV2.__typename !== 'CartResponse') {
          setError();
        } else {
          redemptionErrorRef.current = '';
          return true;
        }
      } catch(_) {
        setError();
      }
    }
    return false;
  }, [addLoyalty, cartGuid, loadingRedemptions, setError]);

  const getLoyalty = useCallback(async () => {
    if(customer?.id && cartGuid && cart?.order && loyaltyConsent.allowLookup && ooRestaurant?.loyaltyConfig?.programName) {
      const response = await loyaltyInquiry({ variables: { input: { cartGuid: cartGuid ?? '' } } });
      if(response?.data?.loyaltyInquiryV3.__typename === 'LoyaltyInquiryResponse') {
        const loyaltyResponse : LoyaltyInquiryResponse = response.data.loyaltyInquiryV3;

        dispatchLoyaltyAccount({
          type: LOYALTY_ACCOUNT_ACTION_TYPES.ACCOUNT_LOADED,
          payload: {
            hasLoyaltyAccount: response?.data?.loyaltyInquiryV3.loyaltyAccountIdentifier !== null,
            pointsBalance: loyaltyResponse.pointsBalance || 0,
            redemptions: getLoyaltyOptions(response?.data?.loyaltyInquiryV3, cart?.order as Order),
            currentState: loyaltyResponse.currentState ?? null
          }
        });
      } else {
        Sentry.captureMessage('Failed to load loyalty', { level: 'info', extra: { cartGuid } });
        dispatchLoyaltyAccount({ type: LOYALTY_ACCOUNT_ACTION_TYPES.ACCOUNT_LOAD_ERROR });
      }
    } else if(!loadingCustomer && !loyaltyConsent.lookupLoading) {
      dispatchLoyaltyAccount({ type: LOYALTY_ACCOUNT_ACTION_TYPES.NOT_LOGGED_IN });
    }
  }, [customer?.id, cartGuid, cart?.order, loyaltyConsent.allowLookup, loyaltyConsent.lookupLoading, ooRestaurant?.loyaltyConfig?.programName, loadingCustomer, loyaltyInquiry]);

  useEffect(() => {
    if(!loadingLoyaltyAccount && customerLoyaltyInfoData) {
      if(customerLoyaltyInfoData?.customerLoyaltyInfo?.__typename === 'LoyaltyInquiryResponse') {
        dispatchLoyaltyAccount({
          type: LOYALTY_ACCOUNT_ACTION_TYPES.ACCOUNT_LOADED,
          payload: {
            hasLoyaltyAccount: true,
            pointsBalance: customerLoyaltyInfoData?.customerLoyaltyInfo.pointsBalance || 0,
            redemptions: [],
            currentState: customerLoyaltyInfoData?.customerLoyaltyInfo.currentState ?? null
          }
        });
      } else {
        dispatchLoyaltyAccount({
          type: LOYALTY_ACCOUNT_ACTION_TYPES.ACCOUNT_LOAD_ERROR, payload: {
            hasLoyaltyAccount: false,
            pointsBalance: 0,
            redemptions: [],
            currentState: null
          }
        });
      }
    }
  }, [customerLoyaltyInfoData, loadingLoyaltyAccount]);

  const getCustomerLoyaltyInfo = useCallback(async () => {
    if(customer?.id && ooRestaurant?.guid) {
      await refetchCustomerLoyaltyInfo();
    }
  }, [customer?.id, ooRestaurant?.guid, refetchCustomerLoyaltyInfo]);

  useEffect(() => {
    // We only want to call this inquiry when the customer, cart and order are available.
    // Allow this to retrigger if any of those have changed to avoid stale data by tying that state
    // to the getLoyalty() callback. If getLoyalty is recreated, rerun and make sure the guards are
    // correct in the callback. We use the callback so we can use async/await for easier testing.
    getLoyalty();
  }, [getLoyalty]);

  useEffect(() => {
    if(getFirstOrThirdParty(ooRestaurant?.loyaltyConfig?.programName) === LoyaltyProvider.ToastLoyalty) {
      setAllowLookup(true, false);
    }
  }, [ooRestaurant?.loyaltyConfig?.programName, setAllowLookup]);

  const signupForLoyalty = useCallback( async (customerGuid: string, checkIdentifier?: string) => {
    if(ooRestaurant?.guid) {
      const result = await customerLoyaltySignupMutation({
        variables: {
          input: {
            loyaltyAccountCreationInput: {
              restaurantGuid: ooRestaurant?.guid ?? '',
              transactionGuid: uuid(),
              checkIdentifier: checkIdentifier ?? ''
            },
            customerGuid
          }
        }
      });
      // refetch loyalty account after signing up
      getCustomerLoyaltyInfo();
      return result;
    }
    return undefined;
  }, [customerLoyaltySignupMutation, getCustomerLoyaltyInfo, ooRestaurant?.guid]);

  const context = {
    hasLoyaltyAccount: loyaltyAccount.hasLoyaltyAccount,
    pointsBalance: loyaltyAccount.pointsBalance,
    redemptions: loyaltyAccount.redemptions,
    currentState: loyaltyAccount.currentState,
    removeRedemption,
    addRedemption,
    loadingLoyaltyAccount,
    loadingLoyaltyInquiry: loadingLoyaltyInquiry, // Toggles while running the query to find loyalty. Will be 'false' until the query is run.
    loadingRedemptions,
    redemptionErrorRef,
    setAllowLookup,
    allowLookup: loyaltyConsent.allowLookup,
    loyaltyLoading: loyaltyAccount.loyaltyLoading || loyaltyConsent.lookupLoading || loadingLoyaltyFeatures || loadingSignup,
    loadingSignup,
    loadingLoyaltyFeatures,
    programDescription: loyaltyFeaturesData?.loyaltyFeatures?.programDescription,
    loyaltyFeatures: loyaltyFeaturesData?.loyaltyFeatures?.features,
    signupForLoyalty
  };
  return (
    <LoyaltyContext.Provider value={context}>
      {props.children}
    </LoyaltyContext.Provider>
  );
};

export const useLoyalty = () => {
  const context = useContext(LoyaltyContext);
  if(!context) {
    throw new Error('useLoyalty must be used within a LoyaltyContextProvider');
  }
  return context;
};

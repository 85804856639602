import React, { useCallback } from 'react';

import * as Sentry from '@sentry/react';

import { EmailMarketingPage, PageConfig, RestaurantDataFragment, useEmailMarketingSignupMutation } from 'src/apollo/sites';
import { ToastTOSAndPrivacy } from 'src/public/components/default_template/online_ordering/account/pwlessAuth/legalUtils';

import { useRestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';
import { useSubmissionState } from 'shared/js/hooks/useSubmissionState';

import { EditPathRoot } from 'public/components/default_template/main_page/MainPage';
import BetterTogetherPage from 'public/components/pages/better_together/BetterTogetherPage';

type Props = {
  content?: EmailMarketingPage;
  editPathRoot?: EditPathRoot
  config?: PageConfig | null;
}

type AdditionalPageType = NonNullable<NonNullable<RestaurantDataFragment>['additionalPages']>;
export type EmailMarketingPageType = AdditionalPageType[0]['content'] & { __typename: 'EmailMarketingPage' };

const EmailSignupPage = (props: Props) => {
  const { restaurant } = useRestaurant();
  const [emailSignup] = useEmailMarketingSignupMutation();
  const { submitState, endSubmission, startSubmission, failSubmission } = useSubmissionState();
  const onSubmit = useCallback(async (data: any) => {
    startSubmission();
    try {
      if(restaurant.locations && restaurant.locations[0]?.externalId) {
        const resp = await emailSignup({
          variables: {
            email: data.email,
            restaurantGuid: `${restaurant.locations[0].externalId}`,
            source: 'WEBSITES'
          }
        });
        if(resp.data?.addEmailSubscriptionMutation?.status !== 'SUBSCRIBED') {
          Sentry.captureMessage(`ERROR: ${resp.errors?.[0]?.message}`);
          failSubmission();
        } else {
          endSubmission();
        }
      } else {
        failSubmission();
      }
    } catch(error) {
      failSubmission();
      Sentry.captureMessage(`ERROR: ${error}`);
    }
  }, [emailSignup, restaurant.locations, endSubmission, failSubmission, startSubmission]);

  return (
    <>
      <BetterTogetherPage
        content={props.content}
        editPathRoot={props.editPathRoot}
        config={props.config}
        onSubmit={onSubmit}
        useEmail={true}
        header={props.content?.headerText || 'Sign up for email updates'}
        subheader={props.content?.subheaderText || `Stay up to date on the latest happenings at ${restaurant.name}.`}
        isSubheaderEditable={true}
        successLine1="Thank you for signing up!"
        successLine2={`We’ll keep you updated on the latest happenings at ${restaurant.name}`}
        errorLine1="Oh no!"
        errorLine2="We are having trouble submitting your request. Please try again later."
        submitState={submitState}
        disclaimer={
          <>
            By providing your email, you are agreeing to participate in the marketing program and to be contacted through this email as part of the program.
            <br />
              The program information is subject to <ToastTOSAndPrivacy />.
          </>
        } />
    </>
  );
};

export default EmailSignupPage;

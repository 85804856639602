import { useEffect, useRef } from 'react';

import { RestaurantDataFragment } from 'src/apollo/sites';
import TagManager from 'src/lib/js/react-gtm';


const useGoogleTagManager = (siteRestaurant?: RestaurantDataFragment) => {
  // Initialize Google Tag Manager at most once. It prepends tags to <head> and <body>, so initializing it more than once
  // would add the tags multiple times.
  const tagManagerInitialized = useRef(false);
  useEffect(() => {
    if(siteRestaurant?.pixelsV2.google_tag_manager?.scriptArg && !tagManagerInitialized.current) {
      TagManager.initialize({ gtmId: siteRestaurant.pixelsV2.google_tag_manager.scriptArg });
      tagManagerInitialized.current = true;
    }
  }, [siteRestaurant?.pixelsV2.google_tag_manager?.scriptArg]);
};

export default useGoogleTagManager;

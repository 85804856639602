import React, { forwardRef, ReactNode, RefObject, useEffect, useMemo, useRef, useState } from 'react';

import { PrepTimeIcon } from '@toasttab/buffet-pui-icons';
import classnames from 'classnames';
import { format } from 'date-fns';
import parseISO from 'date-fns/parseISO';
import sortBy from 'lodash/sortBy';

// eslint-disable-next-line camelcase
import { DateTimeRange, Menus_MenuItemTag, TimeBasedRules, DayOfWeek } from 'src/apollo/onlineOrdering';
import { DisplayableMenuItemTag } from 'src/apollo/sites';
import { FulfillmentData, getDateTimeIndexes, useFulfillment } from 'src/public/components/online_ordering/FulfillmentContext';
import { dayStrs, getPickupWindowRuleDuration } from 'src/public/components/online_ordering/pickupWindowUtils';

import { useRestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';
import { useScreenWidth } from 'shared/js/utils/WindowContext';

import { MenuItem } from 'public/components/default_template/menu_section/MenuSection';
import { DEFAULT_COLORS } from 'public/components/default_template/meta/StyleMeta';
import { TagIcon } from 'public/components/default_template/offers/OffersIcons';
import { CalendarIcon, ChefsHatIcon } from 'public/components/default_template/online_ordering/item_tags/ItemTagIcons';
import { useOptionalTimeBasedRules } from 'public/components/online_ordering/TimeBasedRuleContext';

import { generateTextColor, getTagColor, Hex, isContrastCompliant } from './tagColorUtils';

export const MenuItemTags = ({ item, backgroundColor, numOffers }: { item: MenuItem, backgroundColor?: string, numOffers?: number}) => {
  const { timeBasedRulesMap } = useOptionalTimeBasedRules() || { timeBasedRulesMap: {} as NonNullable<ReturnType<typeof useOptionalTimeBasedRules>>['timeBasedRulesMap'] };
  const timeBasedRules = item.guid && timeBasedRulesMap[item.guid];
  const tbrTag = useMemo(
    () => timeBasedRules ?
      <TimeBasedRuleMenuItemTag timeBasedRules={timeBasedRules} backgroundColor={backgroundColor} testId={'time-based-ordering-tag-' + item.guid} /> :
      undefined,
    [timeBasedRules, item.guid, backgroundColor]
  );
  const offerBadge = useMemo(() => numOffers && numOffers > 0 ? <OfferBadge text={numOffers > 1 ? 'OFFERS' : 'OFFER'} /> : undefined, [numOffers]);

  // Regardless of the state of the FF, TBR tags, or any menu item tags, if the item is out of stock, that's all we'll return
  if(item.outOfStock) {
    return (
      <div className="itemTagsContainer" data-testid="menu-item-tags">
        <OutOfStockTag />
      </div>
    );
  }

  const itemTags = item.itemTags ?? [];

  if(itemTags.length === 0 && !tbrTag && !offerBadge) {
    return null;
  }

  return (
    <div className="itemTagsContainer" data-testid="menu-item-tags">
      {tbrTag ? tbrTag : null}
      {offerBadge ? offerBadge : null}
      {itemTags.length > 0 && <DynamicMenuItemTags itemTags={itemTags} backgroundColor={backgroundColor} />}
    </div>
  );
};

// eslint-disable-next-line camelcase
export const filterAndSortTags = (itemTags: Menus_MenuItemTag[], displayableTags: DisplayableMenuItemTag[]) => {
  const sortOrder = sortBy(displayableTags, 'displayOrder').map(tag => tag.menuItemTagId);
  return sortBy(itemTags?.filter(tag => sortOrder.includes(tag.guid)) ?? [], tag => sortOrder.indexOf(tag.guid));
};

// eslint-disable-next-line camelcase
const DynamicMenuItemTags = ({ itemTags, backgroundColor }: {itemTags: Menus_MenuItemTag[], backgroundColor?: string }) => {
  const { restaurant: { meta: { primaryColor } } } = useRestaurant();
  const tagsContainerRef = useRef<HTMLDivElement>(null);
  const firstTagRef = useRef<HTMLDivElement>(null);
  const secondTagRef = useRef<HTMLDivElement>(null);
  const thirdTagRef = useRef<HTMLDivElement>(null);
  const plusMoreRef = useRef<HTMLDivElement>(null);

  const [firstTagFits, setFirstTagFits] = useState(false);
  const [secondTagFits, setSecondTagFits] = useState(false);
  const [thirdTagFits, setThirdTagFits] = useState(false);
  const [firstTagWidth, setFirstTagWidth] = useState<number>();
  const [secondTagWidth, setSecondTagWidth] = useState<number>();
  const [thirdTagWidth, setThirdTagWidth] = useState<number>();
  const [twoTagWidth, setTwoTagWidth] = useState<number>();
  const [threeTagWidth, setThreeTagWidth] = useState<number>();

  const [plusMore, setPlusMore] = useState(0);
  const [firstRender, setFirstRender] = useState(true);
  const screenWidth = useScreenWidth();
  const numTags = itemTags.length;

  useEffect(() => {
    // Calculate how much width each tag would take up if we were to render them so that we can decide how many to ultimately render
    const firstWidth = firstTagRef.current?.getBoundingClientRect().width;
    const secondWidth = secondTagRef.current?.getBoundingClientRect().width;
    const thirdWidth = thirdTagRef.current?.getBoundingClientRect().width;
    setFirstTagWidth(firstWidth);
    setSecondTagWidth(secondWidth);
    setThirdTagWidth(thirdWidth);
    setTwoTagWidth(firstWidth && secondWidth ? firstWidth + 8 + secondWidth : undefined);
    setThreeTagWidth(firstWidth && secondWidth && thirdWidth ? firstWidth + 8 + secondWidth + 8 + thirdWidth : undefined);
    setFirstRender(false);
  }, []);

  useEffect(() => {
    if(!tagsContainerRef.current) {
      return;
    }
    const containerWidth = tagsContainerRef.current.getBoundingClientRect().width;
    const firstFits = !!(firstTagWidth && firstTagWidth < containerWidth);
    const secondFits = !!(twoTagWidth && twoTagWidth < containerWidth);
    const thirdFits = !!(threeTagWidth && threeTagWidth < containerWidth);
    let plusMore = 0;
    if(numTags > 0 && !firstFits) {
      // Not even the first tag fits
      plusMore = numTags;
    } else if(numTags > 1 && !secondFits) {
      // Show first tag plus however many don't fit
      plusMore = numTags - 1;
    } else if(numTags > 2 && secondFits && !thirdFits) {
      // Show first two tags plus however many don't fit
      plusMore = numTags - 2;
    } else if(numTags > 3 && thirdFits) {
      plusMore = numTags - 3;
    }
    setFirstTagFits(firstFits);
    setSecondTagFits(secondFits);
    setThirdTagFits(thirdFits);

    if(plusMore === 0) {
      setPlusMore(plusMore);
      return;
    }
    // Make sure the plusMore tag will fit, and move a tag into a hidden state while increasing plusMore count if not
    const plusMoreWidth = plusMoreRef.current?.getBoundingClientRect().width ?? 0;
    if(secondFits && !thirdFits && twoTagWidth + 8 + plusMoreWidth > containerWidth) {
      setSecondTagFits(false);
      plusMore += 1;
    } else if(thirdFits && threeTagWidth + 8 + plusMoreWidth > containerWidth) {
      setThirdTagFits(false);
      plusMore += 1;
    }
    setPlusMore(plusMore);
  }, [screenWidth, firstTagWidth, secondTagWidth, thirdTagWidth, twoTagWidth, threeTagWidth, tagsContainerRef, numTags, setPlusMore]);

  const [firstTag, secondTag, thirdTag] = itemTags;
  const background = backgroundColor ?? DEFAULT_COLORS.background ?? '#FFFFFF';
  const primary = primaryColor ?? DEFAULT_COLORS.primary ?? '#000000';
  const tagColor = getTagColor(background, primary);
  const textColor = generateTextColor(background as Hex);

  return (
    <div className="dynamicTagContainer" ref={tagsContainerRef}>
      {firstTag && firstTag.name && (firstRender || firstTagFits) &&
          <MenuItemTag ref={firstTagRef} tagColor={tagColor} textColor={textColor} subtle title={firstTag.name.length > 25 ? firstTag.name : undefined}>
            {getDisplayName(firstTag.name)}
          </MenuItemTag>}
      {secondTag && secondTag.name && (firstRender || secondTagFits) &&
          <MenuItemTag ref={secondTagRef} tagColor={tagColor} textColor={textColor} subtle title={secondTag.name.length > 25 ? secondTag.name : undefined}>
            {getDisplayName(secondTag.name)}
          </MenuItemTag>}
      {thirdTag && thirdTag.name && (firstRender || thirdTagFits) &&
          <MenuItemTag ref={thirdTagRef} tagColor={tagColor} textColor={textColor} subtle title={thirdTag.name.length > 25 ? thirdTag.name : undefined}>
            {getDisplayName(thirdTag.name)}
          </MenuItemTag>}
      {plusMore > 0 && <MenuItemTag ref={plusMoreRef} tagColor={tagColor} textColor={textColor} subtle>+{plusMore}</MenuItemTag>}
    </div>
  );
};


const getDisplayName = (name: string) => {
  return (name.length > 25 ? name.substring(0, 25) + '...' : name).toUpperCase();
};

// eslint-disable-next-line react/display-name
export const MenuItemTag = forwardRef(({
  tagColor,
  textColor,
  subtle,
  testId,
  Icon,
  className,
  title,
  children
}: {
  tagColor: string;
  textColor?: string;
  subtle?: boolean;
  testId?: string;
  Icon?: React.FC<any>;
  className?: string;
  title?: string;
  ref?: RefObject<HTMLDivElement>;
  children: ReactNode
}, ref: React.RefObject<HTMLDivElement>) => {
  const style = subtle ? { border: `1px solid ${tagColor}`, color: textColor } : { backgroundColor: tagColor, color: textColor };

  return (
    <div ref={ref} className={classnames('itemTag', className, { subtle })} style={style} title={title} data-testid={testId}>
      {Icon && <Icon color={textColor} />}
      {children}
    </div>
  );
});

export const OutOfStockTag = () => {
  return <MenuItemTag tagColor="#ffcdc7" textColor="#ba1a1a">OUT OF STOCK</MenuItemTag>;
};

export const OfferBadge = ({ text, className }: {text: string, className?: string}) => {
  const { restaurant } = useRestaurant();
  const tagColor = restaurant.ooConfig?.promoCodeConfig?.backgroundColor ?? restaurant.meta.primaryColor ?? DEFAULT_COLORS.primary;
  // Prefer white text over black when contrast is sufficient
  const textColor = isContrastCompliant('#FFFFFF', tagColor) ? '#FFFFFF' : '#000000';

  return (
    <MenuItemTag tagColor={tagColor} textColor={textColor} Icon={TagIcon} className={className} testId="offer-badge">
      {text}
    </MenuItemTag>
  );
};

export const TimeBasedRuleItemModalTag = ({ timeBasedRules, fulfillmentTimeValid, testId }: {timeBasedRules: TimeBasedRules, fulfillmentTimeValid: boolean, testId?: string}) => {
  const { fulfillmentData } = useFulfillment();
  const Icon = getIcon(timeBasedRules);
  let message = `Adding this item to your cart ${timeBasedRules.pickupWindowRule ? 'may' : 'will'} require an update to your order time.`;
  const content = getTBRContent(timeBasedRules, false, fulfillmentData);
  return (
    <div className="tbrContent" data-testid={testId}>
      <div className="tbrHeader">
        {content}
        {Icon && <Icon color={DEFAULT_COLORS.text ?? '#252525'} />}
      </div>
      {!fulfillmentTimeValid && <span>{message}</span>}
    </div>
  );
};

export const TimeBasedRuleMenuItemTag = ({
  timeBasedRules,
  backgroundColor,
  testId
}: {
  timeBasedRules: TimeBasedRules,
  backgroundColor?: string,
  testId?: string
}) => {
  const { fulfillmentData } = useFulfillment();
  const { restaurant: { meta: { primaryColor } } } = useRestaurant();
  const background = backgroundColor ?? DEFAULT_COLORS.background ?? '#ffffff';
  const tagColor = getTagColor(background, primaryColor);
  const textColor = generateTextColor(background as Hex);
  const content = getTBRContent(timeBasedRules, true, fulfillmentData);

  return (
    <MenuItemTag
      Icon={getIcon(timeBasedRules)}
      tagColor={tagColor}
      textColor={textColor}
      testId={testId}
      subtle>
      {content}
    </MenuItemTag>
  );
};

const getTBRContent = (timeBasedRules: TimeBasedRules, isTag: boolean, fulfillmentData?: FulfillmentData) => {
  if(timeBasedRules.leadTimeRule) {
    const leadTime = timeBasedRules.leadTimeRule.leadTime;
    return isTag ? `${leadTime}HR NOTICE` : `REQUIRES ${leadTime} HR NOTICE`;
  } else if(timeBasedRules.preorderRule) {
    const { start, end } = timeBasedRules.preorderRule.fulfillmentDateRange as DateTimeRange;
    const dateRange = getDateRangeForTag(new Date(parseISO(start)), end ? new Date(parseISO(end)) : undefined);
    return isTag ? dateRange : `PREORDER TODAY FOR PICKUP ${dateRange}`;
  } else if(timeBasedRules.pickupWindowRule) {
    const duration = getPickupWindowRuleDuration(timeBasedRules.pickupWindowRule, dayStrs[new Date().getDay()] || DayOfWeek.Monday);
    const [dayIndex] = getDateTimeIndexes(fulfillmentData?.cartFulfillmentData.fulfillmentDateTime, fulfillmentData?.scheduleData.futureScheduleDates);
    const closingTimeOnSelectedDay = fulfillmentData?.scheduleData.futureScheduleDates[dayIndex || 0]?.times.at(-1)?.time;
    return generatePickupWindowTagContent(duration, isTag, closingTimeOnSelectedDay);
  }
  return '';
};

export const getDateRangeForTag = (start: Date, end?: Date) => {
  const startMonthAndDay = start.toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric'
  })
    .toUpperCase();
  // The front end of the TBR admin spa currently enforces an end time on all ranges, so this should never actually
  // be undefined, but in case it somehow is, we'll treat it as an open-ended range
  if(!end || start.getMonth() === end.getMonth() && start.getDate() === end.getDate()) {
    return startMonthAndDay;
  }
  if(start.getMonth() === end.getMonth()) {
    return `${startMonthAndDay} - ${end.toLocaleDateString('en-US', { day: 'numeric' })
      .toUpperCase()}`;
  }
  return `${startMonthAndDay} - ${end.toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric'
  })
    .toUpperCase()}`;
};


export const generatePickupWindowTagContent = (duration: number, isTag: boolean, closingTime?: string) => {
  const hoursText = duration === 1 ? 'HOUR' : 'HOURS';

  if(closingTime) {
    const endTime = parseISO(closingTime);
    const startTime = new Date(endTime.getTime() - duration * 60 * 60 * 1000);

    const endtimeFormatted = format(endTime, 'h:mm a');
    const startTimeFormatted = format(startTime, 'h:mm a');

    return isTag ? `PICKUP ${startTimeFormatted}-${endtimeFormatted}` : `ORDER PICKUP TIME MUST BE BETWEEN ${startTimeFormatted} AND ${endtimeFormatted}`;
  }

  return isTag ? `PICK UP ${duration} ${hoursText} BEFORE CLOSE` : `ORDER PICKUP TIME MUST BE WITHIN ${duration} ${hoursText} OF CLOSING`;
};


export const getIcon = (timeBasedRules: TimeBasedRules) => {
  if(timeBasedRules.leadTimeRule) {
    return ChefsHatIcon;
  } else if(timeBasedRules.pickupWindowRule) {
    return PrepTimeIcon;
  }
  return CalendarIcon;
};

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { ChevronLeftIcon } from '@toasttab/buffet-pui-icons';

import { PayForCheckInput, usePayForCheckMutation } from 'src/apollo/onlineOrdering';
import { ButtonType, useBookingQuery } from 'src/apollo/sites';
import { reportError } from 'src/lib/js/clientError';
import { useOOClient } from 'src/shared/components/common/oo_client_provider/OOClientProvider';

import Image from 'shared/components/common/Image';
import Button from 'shared/components/common/button';

import CreditCardForm, { EncryptedCreditCardData } from 'public/components/default_template/online_ordering/checkout/payment/CreditCardForm';
import { CartContextProvider } from 'public/components/online_ordering/CartContext';
import { PaymentContextProvider } from 'public/components/online_ordering/PaymentContext';

import { Countdown } from './Countdown';
import ReservationOverview from './ReservationOverview';
import { SeatingLocation, Deposit, timeInFutureInUTC, useReservationCancellation, type OnReservationCancellation } from './reservationUtils';

type DepositPaymentProps = {
  restaurantGuid: string;
  reservationGuid: string;
  numGuests: number;
  serviceArea: SeatingLocation | null;
  selectedDate: Date | null;
  selectedTime: Date | null;
  deposit?: Deposit | null;
  changeSelection?: any;
  timezone: string;
  occasion: string;
  onCancellation: OnReservationCancellation;
  step: number;
  creditCardData?: EncryptedCreditCardData | null;
  setCreditCardData: (arg0: EncryptedCreditCardData | null) => void;
  onDepositPaid: () => void;
  email: string;
};

type ValidatedDepositData = Pick<PayForCheckInput, 'restaurantGuid' | 'email' | 'checkGuid' | 'orderGuid' | 'newCardInput'>

const DepositPayment = ({
  restaurantGuid, reservationGuid, numGuests, serviceArea, selectedDate, selectedTime, changeSelection, timezone, occasion, deposit,
  onCancellation, step, creditCardData, setCreditCardData, onDepositPaid, email
}: DepositPaymentProps) => {
  const { cancel } = useReservationCancellation(reservationGuid, restaurantGuid, onCancellation, false);
  const [paymentError, setPaymentError] = useState<string | null>(null);

  const { data: bookingData } = useBookingQuery({
    variables: {
      bookingGuid: reservationGuid,
      restaurantGuid: restaurantGuid
    },
    skip: !reservationGuid || !restaurantGuid,
    ssr: false
  });

  const validatedPaymentInput: ValidatedDepositData | null = useMemo(() => {
    const checkGuid = bookingData?.booking?.depositCheckId;
    const orderGuid = bookingData?.booking?.depositOrderId;
    if(checkGuid && creditCardData && email && orderGuid && restaurantGuid) {
      return {
        restaurantGuid,
        email,
        checkGuid,
        orderGuid,
        newCardInput: creditCardData
      };
    }
    return null;
  }, [bookingData, email, restaurantGuid, creditCardData]);

  const [payForCheckMutation, { loading: submittingPayment }] = usePayForCheckMutation({ client: useOOClient() });

  const submitPayment = useCallback(async (
    input: ValidatedDepositData
  ): Promise<true> => {
    try {
      const res = await payForCheckMutation({
        variables: {
          input: {
            ...input,
            tipAmount: 0
          }
        }
      });
      if(res.data?.payForCheck?.__typename === 'PayForCheckError') {
        throw res.data.payForCheck;
      }
    } catch(err) {
      throw new Error('Error encountered while submitting deposit payment information. ', { cause: err } );
    }
    return true;
  }, [payForCheckMutation]);

  const payReservationDeposit = useCallback(async () => {
    if(!validatedPaymentInput) {
      // notify the customer that their info isn't valid.
      setPaymentError('Please complete all required fields.');
      return;
    }
    let paymentSuccessful;
    try {
      paymentSuccessful = await submitPayment(validatedPaymentInput);
    } catch(err) {
      if('message' in err) {
        setPaymentError(err.message as string);
      } else {
        reportError(err);
        setPaymentError('Payment failed.');
      }
    }
    if(paymentSuccessful) {
      onDepositPaid();
    } else {
      reportError('Reservation deposit payment failed');
      setPaymentError('An unknown error occurred. Please try again later.');
    }
  }, [submitPayment, onDepositPaid, validatedPaymentInput]);

  return (
    <div className="depositPayment" data-testid="deposit-payment">
      <div className="currentSelection">
        <ReservationOverview numGuests={numGuests} selectedDate={selectedDate} selectedTime={selectedTime} serviceArea={serviceArea} timezone={timezone} occasion={occasion} />
      </div>
      <div>
        <hr className="menuDividingLine menuDividingLineMarginTop" />
        <div className="depositDescription">
          <p className="depositDescriptionTitle">Reservation Deposit</p>
          {deposit?.strategyType === 'ByBooking' ?
            <div>
              {deposit && deposit.actualAmount &&
                <p className="depositMessage">${formatPrice(deposit.actualAmount)} deposit per booking</p> }
              <div className="depositPaymentContainer">
                <p className="depositMessage">Reservation x1</p>
                {deposit && deposit.actualAmount &&
                  <p className="depositMessage">${formatPrice(deposit.actualAmount)}</p> }
              </div>
            </div>
            :
            <div>
              {deposit && deposit.amountPerGuest &&
                <p className="depositMessage" data-testid="per-person-deposit-disclosure">We require a <span>${formatPrice(deposit.amountPerGuest)} deposit per person</span></p> }
              <div className="depositPaymentContainer">
                <p className="depositMessage">Reservation x{numGuests}</p>
                {deposit && deposit.actualAmount &&
                  <p className="depositMessage">${formatPrice(deposit.actualAmount)}</p> }
              </div>
            </div> }
          <hr className="menuDividingLine menuDividingLinePaddingBottom" />
          <div className="depositPaymentContainer">
            <p className="depositAmountDue">Amount Due</p>
            {deposit && deposit.actualAmount &&
              <p className="depositAmountDue" data-testid="amount-due">${deposit.actualAmount.toFixed(2)}</p> }
          </div>
          {deposit?.cancellationRefundableTimeframe !== undefined &&
            deposit.cancellationRefundableTimeframe > 0 ?
            <p className="depositMessage">Refundable if you cancel up to <b>{deposit.cancellationRefundableTimeframe} hours</b> before your reservation time. Deposits will be
              applied to your bill on the date of your reservation, with any balance returned to you.
            </p>
            : deposit?.cancellationRefundableTimeframe === -1 ?
              <p className="depositMessage">This deposit is non-refundable.</p>
              : <p className="depositMessage">This deposit is refundable until the time of your reservation.</p> }
        </div>
        {paymentError && <DepositError errorMessage={paymentError} />}
        <CartContextProvider>
          <PaymentContextProvider>
            <CreditCardForm onCardChange={setCreditCardData} />
          </PaymentContextProvider>
        </CartContextProvider>
        <p className="countdown">
          Holding reservation for{' '}
          <Countdown
            expirationDate={timeInFutureInUTC(deposit?.autoCancelUnpaidDeposit ? deposit.autoCancelUnpaidDepositTimeframe ?? 15 : 15)}
            reservationGuid={reservationGuid}
            restaurantGuid={restaurantGuid}
            step={step}
            onCancellation={onCancellation} />
        </p>
      </div>
      <span className="findTableButton">
        <div className="backButton" onClick={() => { changeSelection(); cancel(); }}>
          <ChevronLeftIcon />
          <button type="button" className="changeSelection">Back</button>
        </div>
        <Button variant={ButtonType.Primary} disabled={submittingPayment || !validatedPaymentInput} onClick={() => { payReservationDeposit(); }}>Complete Booking</Button>
      </span>
    </div>
  );
};

type DepositErrorProps = {
  errorMessage: string
}

const formatPrice = (amount: number) => amount % 1 !== 0 ? amount?.toFixed(2) : amount;

const DepositError = ({ errorMessage }: DepositErrorProps) => {
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    // Scroll to this error on first render.
    if(ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);
  return (
    <div className="depositError" ref={ref}>
      <Image className="warning-icon" alt="Warning icon" src="icons/warning-red.svg" />
      <div>
        <p><strong>Payment Unsuccessful</strong></p>
        <p>{errorMessage}</p>
      </div>
    </div>
  );
};

export default DepositPayment;

import React, { useMemo } from 'react';

import { ApolloProvider } from '@apollo/client';
import { ContainsEditableProps, useEditor } from '@toasttab/sites-components';
import classnames from 'classnames';
import { v4 as uuidv4 } from 'uuid';

import { DoMenus_GetMenuGroupsGroup as DoMenusGetMenuGroupsGroup } from 'src/apollo/onlineOrdering';
import { MenuGroupIdentifier } from 'src/apollo/sites';

import { useBulkCalls } from 'shared/components/common/dynamic_menus/BulkCallsContext';
import { MenuItemBlockBackupContent } from 'shared/components/common/dynamic_menus/menu_item/MenuItem';
import { useOOClient } from 'shared/components/common/oo_client_provider/OOClientProvider';

import LinkedItem from './LinkedItem';

type TestProps = { testId?: string }

export type GroupItemBackupContent = {guid: string} & MenuItemBlockBackupContent;

export type MenuGroupBlockBackupContent = {
  name: string
  description: string
  items: GroupItemBackupContent[]
}

export type ImagePlacementOptions = 'left' | 'right'

export type MenuGroupBlockConfig = {
  appearance: {
    showMenuGroupTitle?: boolean
    showPrice?: boolean
    showDescription?: boolean
    imagePlacement?: ImagePlacementOptions
    columns?: number
  }
}

export type MenuGroupEditableProps = {
  classNames?: string;
  editableRef?: any;
  id: MenuGroupIdentifier
  backupContent: MenuGroupBlockBackupContent
  config: MenuGroupBlockConfig | null
  styles?: any
} & TestProps & ContainsEditableProps;

const placeholderMenuGroup = {
  name: 'Menu Group',
  items: [
    { guid: uuidv4() },
    { guid: uuidv4() },
    { guid: uuidv4() },
    { guid: uuidv4() }
  ]
} as DoMenusGetMenuGroupsGroup;

const EditableMenuGroup = ({ styles = {}, ...props }: MenuGroupEditableProps) => {
  const { menuGroups: maybeMenuGroups } = useBulkCalls();
  const { editPath, id, backupContent } = props;

  const { useEditableRef } = useEditor();
  const { editableRef } = useEditableRef<HTMLDivElement>({
    name: 'menu group',
    displayName: 'Menu Group',
    actions: ['delete'],
    path: editPath,
    schema: { fields: [] }
  });

  const menuGroup = useMemo(() => {
    if(maybeMenuGroups.length > 0) {
      return maybeMenuGroups.find(group => group.guid === id.groupGuid) || backupContent || placeholderMenuGroup;
    } else {
      return backupContent || placeholderMenuGroup;
    }
  }, [backupContent, id.groupGuid, maybeMenuGroups]);

  const columnsClass = useMemo(() => {
    const numColumns = props.config?.appearance?.columns || 1;
    return `columns${numColumns}`;
  }, [props.config?.appearance?.columns]);

  return (
    <div ref={editableRef}>
      {menuGroup &&
          <div className="groupContainer" style={{ ...styles }}>
            <div className="groupWrapper">
              {props.config?.appearance?.showMenuGroupTitle !== false &&
              <div className="groupDetails">
                <div className="groupName">{menuGroup.name || backupContent?.name || placeholderMenuGroup.name}</div>
                <div className="groupDescription">{menuGroup.description || backupContent?.description}</div>
              </div>}
              <div className={classnames('groupItems', columnsClass)}>
                {
                  menuGroup.items.filter(item => !!item)
                    .map(item => {
                      return (
                        <LinkedItem item={item} key={item.guid} {...props} />
                      );
                    })
                }
              </div>
            </div>
          </div>}
    </div>
  );
};

const WrappedEditableMenuGroup = (props: MenuGroupEditableProps) => {
  const ooClient = useOOClient();
  return (
    <ApolloProvider client={ooClient}>
      <EditableMenuGroup {...props} />
    </ApolloProvider>
  );
};


export default WrappedEditableMenuGroup;

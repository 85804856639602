import isNil from 'lodash/isNil';

import { NavConfig } from 'src/apollo/sites';

import { Restaurant } from 'shared/components/common/restaurant_context/RestaurantContext';
import { hexToRgbA } from 'shared/js/colorUtils';

import { DEFAULT_COLORS } from 'public/components/default_template/meta/StyleMeta';

export const getNavOpacity = (navConfig?: NavConfig | null) => !isNil(navConfig?.opacity) ? navConfig!.opacity / 100 : null;

export const getNavColorStyles = (restaurant?: Restaurant | null, withOpacity?: boolean) => {
  const theme = restaurant?.theme;
  if(theme?.enabled) {
    return {
      color: theme.colorOverrides?.navigation?.text ?? theme.colorScheme.text.default,
      backgroundColor: theme.colorOverrides?.navigation?.background ?? theme.colorScheme.surface.default
    };
  } else {
    const navConfig = restaurant?.content?.navConfig;
    const textColor = navConfig?.textColor || restaurant?.meta?.textColor || DEFAULT_COLORS.text;
    let backgroundColor = navConfig?.backgroundColor || restaurant?.meta?.backgroundColor || DEFAULT_COLORS.background;
    const backgroundColorRGBA = hexToRgbA(backgroundColor, withOpacity ? getNavOpacity(navConfig) : null);
    return {
      ...backgroundColorRGBA && { backgroundColor: backgroundColorRGBA },
      color: textColor
    };
  }
};

export const getHamburgerLeftRight = (): string => {
  try {
    const hamburgers = Array.from(document.getElementsByClassName('hamburger') || [null]);
    if(!hamburgers.length || !hamburgers[0]) {
      return 'left';
    }

    const hLeft = hamburgers[0].getBoundingClientRect().left;
    return hLeft < document.body.clientWidth / 2 ? 'left' : 'right';
  } catch(e) {
    // Do nothing
  }

  return 'left';
};

export const getOverlayStyles = () => {
  const hamburgerPosition = getHamburgerLeftRight();
  return hamburgerPosition === 'left' ? { alignItems: 'flex-start' } : { alignItems: 'flex-end' };
};

import React, { Suspense, useMemo } from 'react';

import { useEditor } from '@toasttab/sites-components';
import classnames from 'classnames';

import { BackgroundImageFit, BaseSectionFieldsFragment, PaddingEnum } from 'src/apollo/sites';
import { getImageUrl } from 'src/shared/components/common/Image';
import AttributionContextProvider from 'src/shared/components/common/attribution_context/AttributionContext';

import { resources } from 'config';

type BaseProps = {
  index: number
  testId?: string
  attributionUtmTerm: string
  className?: string
}
type Props = BaseProps & Omit<BaseSectionFieldsFragment, '__typename'>;

const getBackgroundStyle = (backgroundImageFit: BackgroundImageFit | null | undefined, screenType?: string) => {
  // for mobile, swap fit/fill
  if(screenType === 'mobile') {
    switch(backgroundImageFit) {
      case BackgroundImageFit.Fit:
        return getBackgroundStyle(BackgroundImageFit.Fill);
      case BackgroundImageFit.Fill:
        return getBackgroundStyle(BackgroundImageFit.Fit);
      case BackgroundImageFit.Center:
      case BackgroundImageFit.Stretch:
      case BackgroundImageFit.Tile:
        return getBackgroundStyle(backgroundImageFit);
      // default to Fit
      default:
        return getBackgroundStyle(BackgroundImageFit.Fit);
    }
  }
  switch(backgroundImageFit) {
    // fits image to screen height, cropped sides
    case BackgroundImageFit.Fit:
      return {
        backgroundSize: 'auto 100%',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center'
      };
      // fits image by changing aspect ratio, no cropping
    case BackgroundImageFit.Stretch:
      return {
        backgroundSize: '100% 100%',
        backgroundRepeat: 'no-repeat'
      };
    case BackgroundImageFit.Tile:
      return { backgroundRepeat: 'repeat' };
    case BackgroundImageFit.Center:
      return {
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center'
      };
    // default - fill
    // fits image to screen width, cropped top/bottom
    default:
      return {
        backgroundSize: '100% auto',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center'
      };
  }
};

const SectionWrapper = ({ children, index, testId, attributionUtmTerm, className, ...section } : React.PropsWithChildren<Props>) => {
  const { screenType } = useEditor();

  const backgroundColorStyle = useMemo(() => section.backgroundColor ? { backgroundColor: section.backgroundColor } : {}, [section.backgroundColor]);
  const backgroundImageStyle = useMemo(
    () => {
      if(!section.backgroundImage) {
        return {};
      }
      if(!section?.backgroundImage?.src && !section?.backgroundImage?.displaySrc) {
        return {};
      }

      const backgroundImageFit = section.backgroundImage?.backgroundImageFit;
      let backgroundStyle = getBackgroundStyle(backgroundImageFit, screenType);

      return {
        ...backgroundStyle,
        backgroundImage: `url(${
          getImageUrl(
            section.backgroundImage?.displaySrc || section.backgroundImage?.src,
            section.backgroundImage?.displaySrc ? resources.imagePrefix : resources.publicAssetURL
          )
        })`
      };
    }, [section.backgroundImage, screenType]
  );
  const padding = useMemo(() => section.padding || PaddingEnum.Medium, [section.padding]);
  const { moduleWrapper } = useEditor();

  const WrapperComponent = useMemo(() => moduleWrapper || (({ children }: React.PropsWithChildren<{ index: number; }>) => <>{children}</>), [moduleWrapper]);

  return (
    <WrapperComponent index={index}>
      <AttributionContextProvider utm_term={attributionUtmTerm}>
        <div
          id={`sectionIdx${index}`}
          className={classnames('sectionWrapper bottomPadding topPadding', `PADDING-SETTING--${padding}`, className)}
          style={{ ...backgroundColorStyle, ...backgroundImageStyle }}
          data-testid={testId}>
          <Suspense>
            {children}
          </Suspense>
        </div>
      </AttributionContextProvider>
    </WrapperComponent>
  );
};

export default SectionWrapper;

import React, { useMemo, useState } from 'react';
import { useWatch } from 'react-hook-form';

import { useFlags } from 'launchdarkly-react-client-sdk';

import { useRestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';
import useWindowMessage from 'shared/js/hooks/useWindowMessage';

import { useCart } from 'public/components/online_ordering/CartContext';
import { useCheckout, OrderError } from 'public/components/online_ordering/CheckoutContext';
import { usePayment } from 'public/components/online_ordering/PaymentContext';

import { resources } from 'config';

import { AdyenMessage } from './types';
import useAdyenPayments from './useAdyenPayments';

type Props = {
  setShowAdyenOverlay: (show: boolean) => void
};

const AdyenPaymentIFrame = ({ setShowAdyenOverlay }: Props) => {
  const [height, setHeight] = useState('100%');
  const { ooRestaurant } = useRestaurant();
  const { cartGuid, refetchCart } = useCart();
  const { orderTotal, setOrderError } = useCheckout();
  const { tipAmount } = usePayment();
  const { intlOoAdyenBlockRedirect } = useFlags();

  const guestEmail = useWatch({ name: 'yourInfoEmail' });
  const shortUrl = ooRestaurant?.shortUrl;

  const { placeAdyenOrder } = useAdyenPayments(setShowAdyenOverlay);

  useWindowMessage({
    origin: resources.sitesHost,
    sourceId: 'ADYEN_PAYMENT',
    messageHandlers: {
      [AdyenMessage.HEIGHT]: (data: { height: string }) => setHeight(data.height),
      [AdyenMessage.SHOW_OVERLAY]: () => setShowAdyenOverlay(true),
      [AdyenMessage.HIDE_OVERLAY]: () => setShowAdyenOverlay(false),
      [AdyenMessage.ERROR]: (data: { error: OrderError }) => setOrderError(data.error),
      [AdyenMessage.REFETCH_CART]: () => refetchCart(),
      [AdyenMessage.PLACE_ORDER]: (data: { paymentIntentID: string }) => placeAdyenOrder(data.paymentIntentID)
    }
  });

  const src = useMemo(() =>
    `${resources.sitesHost}/sites-web/v1/adyen?shortUrl=${shortUrl}&cartGuid=${cartGuid}&checkAmount=${orderTotal - tipAmount}` +
    `&tipAmount=${tipAmount}&email=${guestEmail}&blockRedrect=${intlOoAdyenBlockRedirect}`,
  [shortUrl, cartGuid, orderTotal, tipAmount, guestEmail, intlOoAdyenBlockRedirect]);

  // allow list for GPay and ApplePay
  return <iframe title="Adyen payment" allow="payment; camera; microphone" width="100%" height={height} style={{ border: 0 }} src={src} />;
};

export default AdyenPaymentIFrame;

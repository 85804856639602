import React, { useCallback } from 'react';
import { useWatch } from 'react-hook-form';

import { AdyenPayment } from '@toasttab/do-secundo-adyen-payment';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { I18n } from 'src/apollo/onlineOrdering';
import useTracker from 'src/lib/js/hooks/useTracker';

import { useRestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';

import { useHandleValidationError } from 'public/components/default_template/online_ordering/checkout/checkoutUtils';
import { useIsCartValid } from 'public/components/default_template/online_ordering/checkout/useIsCartValid';
import { useCart } from 'public/components/online_ordering/CartContext';
import { useCheckout } from 'public/components/online_ordering/CheckoutContext';
import { usePayment } from 'public/components/online_ordering/PaymentContext';

import useAdyenPayments from './useAdyenPayments';

type Props = {
  setShowAdyenOverlay: (show: boolean) => void
};

type BaseProps = {
  rxGuid: string
  i18n: I18n
  cartGuid: string
  checkAmount: number
  tipAmount: number
  guestEmail: string
  blockRedirect: boolean
  preSubmit: () => Promise<boolean>
  onPaymentSubmitted: () => void
  onPaymentFailed: () => void
  onPaymentCompleted: (intentID: string) => void
};

export const AdyenPaymentWrapper = ({ rxGuid, i18n, cartGuid, checkAmount, tipAmount, guestEmail, blockRedirect, preSubmit, onPaymentSubmitted, onPaymentFailed, onPaymentCompleted }: BaseProps) => {
  const tracker = useTracker();

  return (
    <AdyenPayment
      payment={{ checkAmount, tipAmount, emailAddress: guestEmail }}
      onEvent={event => {
        if(event === 'PAYMENT_SUBMITTED') {
          onPaymentSubmitted();
        }
        if(['POLLING_ERROR', 'POLLING_TIMEOUT', 'PAYMENT_REJECTED'].includes(event)) {
          onPaymentFailed();
        }
      }}
      config={{
        currency: i18n.currency,
        locale: i18n.locale,
        country: i18n.country,
        guid: rxGuid,
        channel: 'ONLINE_ORDERING',
        attributes: cartGuid ? { cartGuid } : undefined
      }}
      preSubmitEvent={preSubmit}
      shouldBlockRedirect={data => {
        tracker.track('adyen_should_block_redirect', {
          cartGuid,
          url: data?.url,
          method: data?.method
        });

        return blockRedirect;
      }}
      onActionHandled={data => {
        tracker.track('adyen_action_handled', {
          cartGuid,
          componentType: data?.componentType,
          actionDescription: data?.actionDescription
        });
      }}
      onPaymentCompleted={paymentIntentID => {
        tracker.track('adyen_payment_completed', {
          cartGuid,
          paymentIntentID
        });

        onPaymentCompleted(paymentIntentID);
      }} />
  );
};

const AdyenPaymentForm = ({ setShowAdyenOverlay }: Props) => {
  const { ooRestaurant } = useRestaurant();
  const { cartGuid, refetchCart } = useCart();
  const { orderTotal } = useCheckout();
  const onValidationError = useHandleValidationError();
  const { isCartValid } = useIsCartValid(onValidationError, refetchCart);
  const { tipAmount } = usePayment();
  const { intlOoAdyenBlockRedirect } = useFlags();

  const { placeAdyenOrder } = useAdyenPayments(setShowAdyenOverlay);

  const guestFirstName = useWatch({ name: 'yourInfoFirstName' });
  const guestLastName = useWatch({ name: 'yourInfoLastName' });
  const guestEmail = useWatch({ name: 'yourInfoEmail' });
  const guestPhone = useWatch({ name: 'yourInfoPhone' });

  const validateCart = useCallback(async () => {
    return !!cartGuid && await isCartValid(
      cartGuid,
      {
        firstName: guestFirstName,
        lastName: guestLastName,
        email: guestEmail,
        phone: guestPhone
      }
    );
  }, [cartGuid, isCartValid, guestFirstName, guestLastName, guestEmail, guestPhone]);

  const i18n = ooRestaurant?.i18n;
  const guid = ooRestaurant?.guid;

  if(!i18n || !guid || !cartGuid) {
    return null;
  }

  return (
    <AdyenPaymentWrapper
      rxGuid={guid}
      i18n={i18n}
      cartGuid={cartGuid}
      checkAmount={orderTotal - tipAmount}
      tipAmount={tipAmount}
      guestEmail={guestEmail}
      blockRedirect={intlOoAdyenBlockRedirect}
      preSubmit={validateCart}
      onPaymentSubmitted={() => setShowAdyenOverlay(true)}
      onPaymentFailed={() => setShowAdyenOverlay(false)}
      onPaymentCompleted={(paymentIntentID: string) => placeAdyenOrder(paymentIntentID)} />
  );
};

export default AdyenPaymentForm;
